import { render, staticRenderFns } from "./lesson-cards.vue?vue&type=template&id=76a5b33d&"
var script = {}
import style0 from "./lesson-cards.vue?vue&type=style&index=0&id=76a5b33d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VContainer,VImg})
