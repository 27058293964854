<template>
	<div class="lesson">
		<div class="relative z-index-1">
			<lesson-sticky-header/>
			<lesson-intro/>
			<lesson-form id="form"/>
			<template v-if="isStudent">
				<lesson-installment/>
				<!-- <lesson-tax/> -->
			</template>
			<template v-else>
				<lesson-cards/>
			</template>
			<lesson-tinkoff/>
			<lesson-screenshots/>
			<lesson-control/>
			<lesson-comments/>
			<lesson-form2 :is-final="true"/>
			<lesson-footer/>
		</div>
		<div class="lesson__bg"></div>
		<div class="lesson__bg2"></div>
	</div>
</template>

<script>
	import LessonStickyHeader from "@/components/Lesson/lesson-sticky-header";
	import LessonIntro from "@/components/Lesson/lesson-intro";
	import LessonCards from "@/components/Lesson/lesson-cards";
	import LessonTinkoff from "@/components/Lesson/lesson-tinkoff";
	import LessonScreenshots from "@/components/Lesson/lesson-screenshots";
	import LessonForm from "@/components/Lesson/lesson-form";
	import LessonForm2 from "@/components/Lesson/lesson-form2";
	import LessonInstallment from "@/components/Lesson/lesson-installment";
	import LessonControl from "@/components/Lesson/lesson-control";
	import LessonComments from "@/components/Lesson/lesson-comments";
	import LessonFooter from "@/components/Lesson/lesson-footer";
	import LessonTax from "@/components/Lesson/lesson-tax";

	export default {
		components: {
			LessonStickyHeader,
			LessonIntro,
			LessonCards,
			LessonTinkoff,
			LessonScreenshots,
			LessonForm,
			LessonForm2,
			LessonInstallment,
			LessonControl,
			LessonComments,
			LessonFooter,
			LessonTax,
		},
		props: {
			who: {},
		},
		computed: {
			isStudent() {
				return this.$store.state.isStudent;
			},
		},
		created() {
			this.$store.state.isStudent = this.who === "student";
		},
	};
</script>

<style lang="scss">
	.lesson {
		position: relative;

		&__bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 780px;
			background: linear-gradient(
							180deg,
							#ffc157 -2.56%,
							#ffffff 100% //#fcfcfc 75.07%,
				//#ffc157 105.3%
			);
		}

		&__bg2 {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1400px;
			background: linear-gradient(180deg, #ffffff 0%, #ffc157 105.3%);
		}

		// общие стили для это страницы:
		a {
			color: $black !important;
		}

		h2 {
			font-size: 34px;
			line-height: 36px;

			@include up($sm) {
				font-size: 60px;
				line-height: 62px;
				letter-spacing: -0.381645px;
			}
		}

		// расстояния между секциями:

		&-form {
			@include up($sm) {
				margin-bottom: 20px;
			}
			@include up($md) {
				margin-bottom: 20px;
			}
		}

		&-cards {
			margin-bottom: 45px;
			@include up($sm) {
				margin-bottom: 30px;
			}
			@include up($md) {
				margin-bottom: 30px;
			}
		}

		&-tinkoff {
			margin-top: 20px;
			margin-bottom: 25px;
			@include up($sm) {
				margin-top: 37px;
				margin-bottom: 37px;
			}
			@include up($md) {
				margin-bottom: 63px;
			}
		}

		&-screenshots {
			margin-bottom: 19px;
			@include up($sm) {
				margin-bottom: 50px;
			}
			@include up($md) {
				margin-bottom: 96px;
			}
		}

		&-control {
			margin-bottom: 66px;
			@include up($sm) {
				margin-bottom: 96px;
			}
			@include up($md) {
				margin-bottom: 149px;
			}
		}

		&-comments {
			margin-bottom: 52px;
		}

		&-tax {
			margin-top: -30px;
		}

		&-installment {
			margin-top: 18px;
			@include up($sm) {
				margin-top: 30px;
			}
			@include up($md) {
				margin-top: 35px;
			}
		}
	}
</style>