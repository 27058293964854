<template>
  <section class="lesson-form2">
    <v-container>
      <div class="lesson-form2__board rounded pa-0 py-sm-6 py-md-9 px-sm-9">
        <div
          class="
            lesson-form2__card
            rounded
            d-sm-none
            px-4
            py-5
            relative
            z-index-1
          "
        >
          <h2 v-if="isStudent">
            Начните изучать<br />
            второй предмет<br />
            с выгодой<br />
          </h2>
          <h2 v-else>
            Попробуйте<br />
            на бесплатном<br />
            уроке
            и сами<br />
            всё поймёте
          </h2>
          <v-img
            src="@/assets/lesson/lesson-form/girl2-sm.png"
            alt="Ребёнок"
            contain
            class="lesson-form2__girl"
          />
        </div>
        <div
          class="lesson-form2__item--1 d-none d-sm-inline relative z-index-1"
        >
          <h2 v-if="isStudent">
            Начните изучать<br />
            второй предмет<br />
            с выгодой<br />
          </h2>
          <h2 v-else>
            Попробуйте<br />
            на бесплатном<br class="d-none d-md-inline" />
            уроке,<br class="d-md-none" />
            и сами<br class="d-none d-md-inline" />
            всё поймёте
          </h2>
        </div>
        <div
          class="
            lesson-form2__item--2
            px-4 px-sm-0
            mt-5 mt-md-14
            mb-6
            ma-sm-0
            relative
            z-index-1
          "
        >
          <template v-if="isStudent">
            <div class="d-flex">
              <!-- <span class="pr-2">→</span> -->
              <span
                >+2 урока по новым<br />
                предметам</span
              >
            </div>
            <!-- <div class="d-flex">
              <span class="pr-2">→</span
              ><span
                >До +3 при оплате до вводного
                <br class="d-none d-sm-inline" />урока</span
              >
            </div> -->
          </template>
          <template v-else>
            <div class="lesson-form2__promocode-text pl-sm-5 pl-md-0">
              +3 урока в подарок<br />
              по промокоду <br class="d-none" />
              <span class="lesson-form2__promocode">
                &emsp;&emsp;&emsp;
                <img
                  v-if="isB2B"
                  src="@/assets/lesson/lesson-form/promocode2-2x.png"
                  alt="promocode"
                  class="lesson-form2__promocode-img--2"
                />
                <img
                  v-else
                  src="@/assets/lesson/lesson-form/promocode-2x.png"
                  alt="promocode"
                  class="lesson-form2__promocode-img"
                />
              </span>
              <br class="d-none d-sm-inline" />
              при<br class="d-sm-none" />
              оплате пакета<br class="d-none d-sm-inline" />
              от 8 уроков
            </div>
          </template>
        </div>
        <div
          class="
            lesson-form2__item--3
            px-4 px-sm-0
            pb-7 pb-sm-0
            relative
            z-index-1
          "
        >
          <lesson-form-self />
        </div>

        <v-img
          v-if="isWindowMD"
          src="@/assets/lesson/lesson-form/girl2.png"
          alt="Ребёнок"
          contain
          class="lesson-form2__girl"
        />
        <v-img
          v-else-if="isWindowSM && isStudent"
          src="@/assets/lesson/lesson-form/girl.png"
          alt="Ребёнок"
          contain
          class="lesson-form2__girl student"
        />
        <v-img
          v-else-if="isWindowSM"
          src="@/assets/lesson/lesson-form/girl2-sm.png"
          alt="Ребёнок"
          contain
          class="lesson-form2__girl"
        />
        <div class="lesson-form2__bg" />
      </div>
    </v-container>
  </section>
</template>

<script>
import LessonFormSelf from "./lesson-form-self";
import Config from "@/config";

export default {
  components: {
    LessonFormSelf,
  },
  data() {
    return {};
  },
  computed: {
    isWindowSM() {
      return this.$store.getters.isWindowSM;
    },
    isWindowMD() {
      return this.$store.getters.isWindowMD;
    },
    isStudent() {
      return this.$store.state.isStudent;
    },
    isB2B() {
      return (
        this.$route.query["source_type"] === Config.UTM_SOURCE_TYPE_CORPORATE ||
        this.$route.query["promocode"] === Config.CORPORATE_UTM_PROMOCODE
      );
    },
  },
  mounted() {
    console.log("Lesson promocode: " + (this.isB2B ? "B2BFEST" : "FEST"));
  },
};
</script>

<style lang="scss">
.lesson-form2 {
  h2 {
    font-size: 34px;
    line-height: 36px;

    @include up($sm) {
      font-size: 60px;
      line-height: 62px;
    }
  }

  &__card {
    background: $golden;
    height: 300px;
    position: relative;

    h2 {
      font-size: 34px;
      line-height: 36px;
      color: $white;
    }
  }

  &__board {
    overflow: hidden;
    position: relative;
    background: linear-gradient(180deg, #ffc157 0%, #ffffff 113.03%);
    //background-image: url("~@/assets/lesson/lesson-form/bg.png");

    @include up($sm) {
      //background-image: url("~@/assets/lesson/lesson-form/bg-sm.jpg");
      //background-image: url("~@/assets/lesson/lesson-form/bg.jpg");
      /*background-position: left bottom;
				background-repeat: no-repeat;
				background-size: cover;*/

      display: grid;
      grid-gap: 20px 15px;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "title title" "form text-photo" "form text-photo";
    }
    @include up($md) {
      background-image: url("~@/assets/lesson/lesson-form/bg.jpg");
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: cover;
      grid-template-columns: 2fr 1fr;
      grid-template-areas: "title form" "title form" "text-photo form";
    }
  }

  &__bg {
    position: absolute;
    left: -200px;
    bottom: -20px;
    width: 800px;
    height: 400px;
    background-image: url("~@/assets/lesson/lesson-form/trees.png");
    background-position: left bottom;
    background-repeat: repeat-x;
    background-size: contain;
    opacity: 0.3;

    @include up($sm) {
      opacity: 1;
      width: 1460px;
      height: 335px;
    }
  }

  &__promocode {
    position: relative;
    right: 58px;
    &-img {
      width: 55px;
      height: 28px;
      position: absolute;
      top: -1px;
      left: 56px;
      @include up($sm) {
        top: -5px;
        left: 55px;
      }

      @include up($sm) {
        width: 90px;
        height: 46px;
      }
      &--2 {
        width: 53px;
        height: 21px;
        position: absolute;
        top: 1px;
        left: 57px;
        transform: rotate(-7.77deg);
        @include up($sm) {
          top: -1px;
          left: 59px;
          width: 86px;
          height: 35px;
        }

        @include up($md) {
          left: 57px;
          top: 0;
        }
      }
    }
    &-text {
      font-size: 18px;
      line-height: 24px;
      @include up($sm) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  &__item {
    &--1 {
      @include up($sm) {
        grid-area: title;
      }
    }

    &--2 {
      font-size: 24px;
      line-height: 26px;
      letter-spacing: -0.008em;
      @include up($sm) {
        grid-area: text-photo;
        font-size: 40px;
        line-height: 44px;
        letter-spacing: -1.4px;
      }
    }

    &--3 {
      @include up($sm) {
        grid-area: form;
      }
    }

    &--4 {
      font-size: 12px;
      line-height: 18px;

      @include up($sm) {
        line-height: 16px;
      }
    }
  }

  &__girl {
    display: block;
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 0;
    height: 50%;
    transform: translateX(-50%);
    max-height: 312px;

    @include up($sm) {
      left: unset;
      right: 9%;
      transform: unset;
      height: 43%;

      &.student {
        right: 5%;
        height: 48%;
        max-height: 365px;
      }
    }

    @include up($md) {
      right: unset;
      left: 40%;
      height: 78%;
      bottom: -7%;
      max-height: 436px;

      /*&.student {
					right: unset;
					left: 43%;
					height: 66%;
					max-height: 365px;
				}*/
    }
  }
}
</style>