<template>
	<div class="lesson-comments-item">
		<div class="lesson-comments-item__video">
			<v-img :src="item.image"/>

			<button @click="onPlayPause">
				<svg v-if="item.isPlaying" class="pause" version="1.1" xmlns="http://www.w3.org/2000/svg"
					 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 365 365" style="enable-background:new 0 0 365 365;" xml:space="preserve">
				<g>
					<rect x="74.5" width="73" height="365"/>
					<rect x="217.5" width="73" height="365"/>
				</g>
				</svg>

				<svg v-else version="1.1" xmlns="http://www.w3.org/2000/svg"
					 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 472.615 472.615" style="enable-background:new 0 0 472.615 472.615;"
					 xml:space="preserve">
				<g>
					<polygon points="50.273,0 50.273,472.615 422.342,236.308"/>
				</g>
				</svg>
			</button>

			<button @click="onMute">
				<svg v-if="item.isMuted" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 54 54" style="enable-background:new 0 0 54 54;" xml:space="preserve">
				<g>
					<path d="M46.414,26l7.293-7.293c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L45,24.586l-7.293-7.293
						c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L43.586,26l-7.293,7.293c-0.391,0.391-0.391,1.023,0,1.414
						C36.488,34.902,36.744,35,37,35s0.512-0.098,0.707-0.293L45,27.414l7.293,7.293C52.488,34.902,52.744,35,53,35
						s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L46.414,26z"/>
					<path d="M26.894,4c-0.551,0-1.097,0.153-1.579,0.444c-0.046,0.027-0.09,0.059-0.13,0.093L11.634,16H1c-0.553,0-1,0.447-1,1v19
						c0,0.553,0.447,1,1,1h10.61l13.543,12.436c0.05,0.046,0.104,0.086,0.161,0.12C25.797,49.847,26.343,50,26.894,50
						C28.606,50,30,48.584,30,46.844V7.156C30,5.416,28.606,4,26.894,4z M13,35c0,0.553-0.447,1-1,1s-1-0.447-1-1v-4
						c0-0.553,0.447-1,1-1s1,0.447,1,1V35z M13,22c0,0.553-0.447,1-1,1s-1-0.447-1-1v-4c0-0.553,0.447-1,1-1s1,0.447,1,1V22z"/>
				</g>
				</svg>
				<svg v-else version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
				<g>
					<path d="M26.894,7.358c-0.551,0-1.097,0.153-1.579,0.444c-0.046,0.027-0.09,0.059-0.13,0.093L11.634,19.358H1c-0.553,0-1,0.447-1,1
						v19c0,0.266,0.105,0.52,0.293,0.707S0.734,40.358,1,40.358l10.61-0.005l13.543,12.44c0.05,0.046,0.104,0.086,0.161,0.12
						c0.482,0.291,1.028,0.444,1.579,0.444c1.713,0,3.106-1.416,3.106-3.156V10.514C30,8.774,28.606,7.358,26.894,7.358z M13,38.358
						c0,0.553-0.447,1-1,1s-1-0.447-1-1v-4c0-0.553,0.447-1,1-1s1,0.447,1,1V38.358z M13,25.358c0,0.553-0.447,1-1,1s-1-0.447-1-1v-4
						c0-0.553,0.447-1,1-1s1,0.447,1,1V25.358z"/>
					<path d="M52.026,29.858c0-8.347-5.316-15.76-13.229-18.447c-0.522-0.177-1.091,0.103-1.269,0.626
						c-0.177,0.522,0.103,1.091,0.626,1.269c7.101,2.411,11.872,9.063,11.872,16.553c0,7.483-4.762,14.136-11.849,16.554
						c-0.522,0.178-0.802,0.746-0.623,1.27c0.142,0.415,0.53,0.677,0.946,0.677c0.107,0,0.216-0.017,0.323-0.054
						C46.721,45.611,52.026,38.198,52.026,29.858z"/>
					<path d="M44.453,6.374c-0.508-0.213-1.095,0.021-1.312,0.53C42.926,7.413,43.163,8,43.672,8.216C52.376,11.909,58,20.405,58,29.858
						c0,9.777-5.894,18.38-15.015,21.914c-0.515,0.2-0.771,0.779-0.571,1.294c0.153,0.396,0.532,0.639,0.933,0.639
						c0.12,0,0.242-0.021,0.361-0.067C53.605,49.801,60,40.467,60,29.858C60,19.6,53.897,10.382,44.453,6.374z"/>
					<path d="M43.026,29.858c0-5.972-4.009-11.302-9.749-12.962c-0.53-0.151-1.084,0.152-1.238,0.684
						c-0.153,0.53,0.152,1.085,0.684,1.238c4.889,1.413,8.304,5.953,8.304,11.04s-3.415,9.627-8.304,11.04
						c-0.531,0.153-0.837,0.708-0.684,1.238c0.127,0.438,0.526,0.723,0.961,0.723c0.092,0,0.185-0.013,0.277-0.039
						C39.018,41.159,43.026,35.829,43.026,29.858z"/>
				</g>
				</svg>
			</button>

			<video ref="video" :id="'video'+id" playsinline x5-playsinline="" webkit-playsinline=""
				   preload="none" disablepictureinpicture="" x-webkit-airplay="allow">
				<source type="video/mp4" :src="item.video">
				Your browser does not support the<code>video</code>element.
			</video>

		</div>
		<p v-html="item.text"></p>

	</div>
</template>

<script>
    import Vue from "vue";
    import LessonCommentsItem from "@/components/Lesson/lesson-comments-item";

    let id = 0;

    export default {
        components: {
            LessonCommentsItem,
        },
        props: {
            item: {}
        },
        data: () => {
            return {
                id: null
            }
        },
        methods: {
            onPlayPause() {
                if (this.item.isPlaying) {
                    const video = document.getElementById("video" + this.id);
                    video.pause();
                    Vue.set(this.item, "isPlaying", false);
                } else {
                    // stop all videos on page
                    const videos = [].slice.call(document.getElementsByTagName("video"));
                    videos.forEach(el => {
                        el.pause();
                        //el.muted = true;
                    });

                    // start playing
                    const video = document.getElementById("video" + this.id);
                    video.play();

                    this.$emit("play", this.item);
                    video.muted = false;
                    Vue.set(this.item, "isMuted", false);

                    // пауза на установку сиблингов
                    setTimeout(() => {
                        Vue.set(this.item, "isPlaying", true);
                    }, 150);
                }
            },
            onMute() {
                const video = document.getElementById("video" + this.id);
                Vue.set(this.item, "isMuted", !this.item.isMuted);
                video.muted = this.item.isMuted;
			}
        },
        mounted() {
            this.id = id++;
        }
    }
</script>

<style lang='scss'>
	.lesson-comments-item {
		width: 236px;

		@include up($sm) {
			width: 260px;
		}

		&__video {
			position: relative;
			width: 100%;
			height: 397px;
			border-radius: 12px;
			overflow: hidden;

			@include up($sm) {
				height: 466px;
			}

			video {
				display: block;
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0;
				object-fit: cover;
			}

			button {
				position: absolute;
				z-index: 2;
				left: 10px;
				bottom: 10px;
				width: 36px;
				height: 36px;
				background: #313131;
				border-radius: 50%;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				@include transition();

				&:hover {
					background: rgba(0, 0, 0, .5);
				}

				svg {
					width: 16px;
					height: 16px;
					fill: $golden;
					margin-left: 2px;
				}
				svg.pause {
					margin-left: 0;
				}
			}

			button + button {
				left: 56px;
			}

			.v-image {
				height: 100%;
			}
		}


		p {
			margin: 12px 0 0 0;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: -0.05px;

			@include up($sm) {
				margin: 16px 0 0 0;
				font-size: 28px;
				line-height: 30.8px;
				//letter-spacing: -0.05px;
			}
		}
	}
</style>