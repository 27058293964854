<template>
	<div class="home-speakers-card rounded" :class="componentClass">
		<div class="home-speakers-card__text px-6 py-5 px-md-5 py-md-4">
			<slot name="default"/>
			<div v-if="items && items.length"
					class="home-speakers-card__names d-flex justify-space-between mt-6 mb-1 mb-sm-4 mt-md-4">
				<div
						v-for="(item, index) of items"
						:key="'speakerName' + index"
						v-html="nameHtml(item)"></div>
			</div>
		</div>
		<div v-if="items && items.length" class="home-speakers-card__images">
			<div v-for="(item, index) of items" :key="'speakerImage' + index">
				<v-img contain :src="item.image" :alt="item.name"/>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            bg: {
                type: String | Number,
            },
        },
        computed: {
            componentClass() {
                return ["bg" + this.bg];
            },
        },
        methods: {
            nameHtml(item) {
                return item.name.replace(/ /, "<br/>");
            },
        },
    };
</script>

<style lang='scss'>
	.home-speakers-card {
		position: relative;
		overflow: hidden;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		&.bg {
			&1 {
				background-image: url("~@/assets/speakers/bg1.jpg");
			}

			&2 {
				background-image: url("~@/assets/speakers/bg2.jpg");
			}

			&3 {
				background-image: url("~@/assets/speakers/bg3.jpg");
			}

			&4 {
				background-image: url("~@/assets/speakers/bg4.jpg");
			}

			&5 {
				background: linear-gradient(180deg, #c2f5ff 0%, #e5f8ff 100%);
			}
		}

		&__names {
			height: 16px;
			max-width: 360px;
			margin: 0 auto;

			font-size: 12px;
			line-height: 14px;
			letter-spacing: -0.0688764px;

			@include up($sm) {
				height: 22px;
				font-size: 18px;
				line-height: 24px;
				letter-spacing: -0.0825909px;
			}
			/*@include up($sm) {
						height: 16px;
					}*/
		}

		&__images {
			position: relative;
			height: 150px;
			max-width: 400px;
			margin: 0 auto;

			@include up($sm) {
				height: 180px;
			}

			@include up($md) {
				height: 160px;
			}

			& > div {
				position: absolute;
				height: 100%;
				max-width: 160px;
				bottom: 0;
				flex: 1;
				display: flex;
				//flex-direction: column;
				justify-content: center;
				text-align: left;

				&:nth-child(1) {
					z-index: 2;
					left: -4%;
					bottom: 0;
					width: 43%;
				}

				&:nth-child(2) {
					z-index: 1;
					left: 50%;
					//right: 0;
					bottom: 0;
					//margin: 0 auto;
					transform: translateX(-50%);
					width: 43%;
				}

				&:nth-child(3) {
					z-index: 0;
					right: -7%;
					bottom: 0;
					width: 43%;
				}
			}
		}
	}
</style>