<template>
	<section class="lesson-comments">
		<v-container>
			<h2>Отзывы о первом уроке</h2>
		</v-container>

		<div class="mt-2 mt-sm-4 lesson-comments__walls">
			<div class="lesson-comments__content">

				<lesson-comments-item
						v-for="(item, index) of items" :item="item" :key="'comment'+index"
						@play="onPlay"/>

			</div>
		</div>

	</section>
</template>

<script>
    import LessonCommentsItem from "@/components/Lesson/lesson-comments-item";
    import Vue from "vue";

    export default {
        components: {
            LessonCommentsItem,
        },
        data: () => {
            return {
                items: [
                    {
                        image: require("@/assets/lesson/lesson-comments/1.jpg"),
                        text: "<span class='golden'>Женя, мама Кирилла:</span><br /> «Нам очень понравилось, что преподавательница прислушалась к нашим пожеланиям»",
                        video: "https://cdn-user84632.skyeng.ru/shared/large-media/skysmart/pages/free-lesson/7.mp4",
                    },
                    {
                        image: require("@/assets/lesson/lesson-comments/2.jpg"),
                        text: "<span class='golden'>Елена, мама Оли:</span><br /> «Понравилось, что преподавательница составила программу на основании слабых мест Оли»",
                        video: "https://cdn-user84632.skyeng.ru/shared/large-media/skysmart/pages/free-lesson/2.mp4",
                    },
                    {
                        image: require("@/assets/lesson/lesson-comments/3.jpg"),
                        text: "<span class='golden'>Алёна:</span><br /> «Понравилось, буду заниматься ещё. Лайк!»",
                        video: "https://cdn-user84632.skyeng.ru/shared/large-media/skysmart/pages/free-lesson/6.mp4",
                    },
                    {
                        image: require("@/assets/lesson/lesson-comments/4.jpg"),
                        text: "<span class='golden'>Маргарита:</span><br />  «Мне очень доступно объяснили материал, а еще тут очень добрые учителя»",
                        video: "https://cdn-user84632.skyeng.ru/shared/large-media/skysmart/pages/free-lesson/3.mp4",
                    }
                ]
            }
        },
		methods: {
            onPlay(item) {
                // всем сиблингам сбросить игру
				this.items.forEach(el=>{
				    Vue.set(el, "isPlaying", false);
				});
			}
		}
    };
</script>

<style lang='scss'>
	.lesson-comments {

		&__walls {
			position: relative;
			overflow-x: auto;
			padding: 0 0 40px 0;
			/*@include up($sm) {
				&:before {
					content: '';
					position: absolute;
					z-index: 2;
					left: 0;
					top: 0;
					width: 50px;
					height: 100%;
					background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
				}
				&:after {
					content: '';
					position: absolute;
					z-index: 2;
					right: 0;
					top: 0;
					width: 50px;
					height: 100%;
					background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
				}
			}
			@include up($md) {
				&:before, &::after {
					display: none;
				}
			}*/
		}

		&__content {
			position: relative;
			padding: 0 $container-padding-x;
			display: flex;
			justify-content: space-between;
			width: 236px * 4 + 26px * 3;
			box-sizing: content-box;
			margin: 0 auto;

			@include up($sm) {
				padding: 0 $container-padding-sm;
				width: 260px * 4 + 40px * 3;
			}

			@include up($md) {
				padding: 0 $container-padding-md;
				width: auto;
				max-width: 1160px;
			}

			/*.lesson-comments-item {
				//margin-left: 26px;
			}*/

			/*

			@include up($md) {
				padding: 0 $container-padding-md;
				overflow-x: hidden;
			}*/
		}
	}
</style>