<template>
  <section class="lesson-tinkoff">
    <v-container>
      <div
        class="
          lesson-tinkoff__board
          pt-7
          px-6
          pt-sm-11
          pl-sm-13
          pt-md-13
          pr-md-13
          relative
        "
      >
        <v-row v-if="isWindowSM">
          <v-col cols="12">
            <h2>
              И подарите ребенку бесплатную<br class="d-none d-md-inline" />
              карту<br class="d-md-none" />
              Тинькофф Джуниор
            </h2>
          </v-col>
          <v-col sm="6" md="8">
            <v-row>
              <v-col cols="12" md="6" order-md="1" class="pb-0 pt-sm-8 pr-md-0">
                <div class="d-flex mb-3 mb-sm-7">
                  <img
                    src="@/assets/lesson/lesson-tinkoff/heart-2x.png"
                    class="lesson-tinkoff__img mr-1"
                  />
                  <div class="lesson-tinkoff__text">
                    Вы спокойны: траты под присмотром
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6" order-md="4" class="py-0 pl-md-0">
                <div class="d-flex mb-3 mb-sm-7">
                  <img
                    src="@/assets/lesson/lesson-tinkoff/prize-2x.png"
                    class="lesson-tinkoff__img mr-1"
                  />
                  <div class="lesson-tinkoff__text">
                    +2 урока Skysmart<br />
                    в подарок после первой<br />
                    покупки по карте
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6" order-md="2" class="py-0 pt-md-8 pl-md-0">
                <div class="d-flex mb-3 mb-sm-7">
                  <img
                    src="@/assets/lesson/lesson-tinkoff/phone-2x.png"
                    class="lesson-tinkoff__img mr-1"
                  />
                  <div class="lesson-tinkoff__text">
                    Приложение<br class="d-md-none" />
                    с советами<br />
                    по финграмотности
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                order-md="3"
                class="py-0 d-sm-none d-md-block pr-md-0"
              >
                <div class="d-flex mb-3 mb-sm-7">
                  <img
                    src="@/assets/lesson/lesson-tinkoff/wallet-2x.png"
                    class="lesson-tinkoff__img mr-1"
                  />
                  <div class="lesson-tinkoff__text">
                    Игровой формат:<br />
                    учим копить<br />
                    на ипотеку смолоду
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                order-md="5"
                class="lesson-tinkoff__width pt-10 pt-md-5 pb-md-14"
              >
                <a class="lesson-tinkoff__link" :href="href" target="_blank">
                  <v-btn height="52" block elevation="0">За картой!</v-btn>
                </a>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="6" md="4">
            <v-row>
              <v-col cols="12" class="py-0 pt-sm-8 d-md-none">
                <div class="d-flex mb-3 mb-sm-7">
                  <img
                    src="@/assets/lesson/lesson-tinkoff/wallet-2x.png"
                    class="lesson-tinkoff__img mr-1"
                  />
                  <div class="lesson-tinkoff__text">
                    Игровой формат:<br />
                    учим копить<br />
                    на ипотеку смолоду
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="pb-sm-0 pt-md-0 relative">
                <v-img
                  src="@/assets/lesson/lesson-tinkoff/screen.png"
                  class="lesson-tinkoff__screen-img d-none d-md-block"
                />
                <v-img
                  src="@/assets/lesson/lesson-tinkoff/screen-sm.png"
                  class="lesson-tinkoff__screen-img d-md-none"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <h2>И подарите ребенку бесплатную карту Тинькофф Джуниор</h2>
          </v-col>
          <v-col cols="12" class="pb-0 pt-4">
            <div class="d-flex mb-3 mb-sm-7">
              <img
                src="@/assets/lesson/lesson-tinkoff/heart-2x.png"
                class="lesson-tinkoff__img mr-1"
              />
              <div class="lesson-tinkoff__text">
                Вы спокойны: траты<br />
                под присмотром
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="py-0">
            <div class="d-flex mb-3 mb-sm-7">
              <img
                src="@/assets/lesson/lesson-tinkoff/prize-2x.png"
                class="lesson-tinkoff__img mr-1"
              />
              <div class="lesson-tinkoff__text">
                +2 урока Skysmart<br />
                в подарок после первой<br />
                покупки по карте
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="py-0">
            <div class="d-flex mb-3 mb-sm-7">
              <img
                src="@/assets/lesson/lesson-tinkoff/phone-2x.png"
                class="lesson-tinkoff__img mr-1"
              />
              <div class="lesson-tinkoff__text">
                Приложение с советами<br />
                по финграмотности
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="py-0">
            <div class="d-flex mb-3 mb-sm-7">
              <img
                src="@/assets/lesson/lesson-tinkoff/wallet-2x.png"
                class="lesson-tinkoff__img mr-1"
              />
              <div class="lesson-tinkoff__text">
                Игровой формат: учим<br />
                копить на ипотеку<br />
                смолоду
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="pa-0 pl-3 pb-0">
            <v-img
              src="@/assets/lesson/lesson-tinkoff/screen-sm.png"
              class="lesson-tinkoff__screen-img"
            />
          </v-col>
          <!-- <div class="lesson-tinkoff__shadow"></div> -->
        </v-row>
        <div class="lesson-tinkoff__shadow d-sm-none">
          <a class="lesson-tinkoff__link" :href="href" target="_blank">
            <v-btn height="52" block elevation="0">За картой!</v-btn>
          </a>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import { scrollTo } from "@/utils/utils";

export default {
  methods: {
    scrollTo(el) {
      scrollTo(el);
    },
  },
  computed: {
    isWindowSM() {
      return this.$store.getters.isWindowSM;
    },
    isWindowMD() {
      return this.$store.getters.isWindowMD;
    },
    isStudent() {
      return this.$store.state.isStudent;
    },
    href() {
      return this.isStudent
        ? "https://go.skyeng.ru/tinkoff-fest-land"
        : "https://go.skyeng.ru/tinkoff-fest-resultp";
    },
  },
};
</script>

<style lang="scss">
.lesson-tinkoff {
  overflow-y: hidden;
  h2 {
    font-size: 34px;
    line-height: 36px;
    letter-spacing: -0.5px;

    @include up($sm) {
      font-size: 60px;
      line-height: 64px;
      letter-spacing: -0.013em;
    }
  }
  &__link {
    text-decoration: none;
  }

  &__shadow {
    // box-shadow: inset 0 -7px 20px -10px rgba(255, 177, 50, 0.4);
    // box-shadow: 0 7px 5px -5px rgba(255, 177, 50, 0.4);
    box-shadow: 0 -5px 5px -5px rgba(255, 177, 50, 0.4);
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 24px;
    background: $pale-orange;
    // height: 10px;
  }

  &__board {
    background: $pale-orange;
    border-radius: 30px;
    padding-bottom: 106px;
    // font-size: 18px;
    // line-height: 24px;
    // letter-spacing: -0.05px;
    overflow: hidden;

    @include up($sm) {
      padding-bottom: unset;
    }

    // padding-top: 20px;
    // padding-left: 20px;
    // padding-bottom: 36px;
    // padding-right: 20px;

    // @include up($sm) {
    //   // font-size: 32px;
    //   // line-height: 38px;
    //   // letter-spacing: -0.008em;

    //   // padding-top: 67px;
    //   // padding-left: 118px;
    //   // padding-bottom: 91px;
    //   // padding-right: 147px;
    // }

    // @include up($md) {
    //   // padding-top: 47px;
    //   // padding-left: 88px;
    //   // padding-bottom: 75px;
    //   // padding-right: 139px;
    // }
  }

  &__img {
    width: 28px;
    height: 28px;

    @include up($sm) {
      width: 36px;
      height: 36px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.05px;

    @include up($sm) {
      font-size: 24px;
      line-height: 110%;
    }
  }

  &__screen {
    // max-height: 240px;
    overflow: hidden;
    // position: relative;
    &-img {
      // max-width: 300px;
      width: 100%;
      @include up($sm) {
        max-width: 330px;
      }
      @include up($md) {
        max-width: 335px;
        position: absolute;
        top: -26px;
        // width: 100%;
      }
    }
  }

  &__width {
    max-width: 320px;
  }
  .v-btn {
    border-radius: 10px;
    height: 52px !important;
    // width: 100%;
    color: $white;
    background-color: $orange2 !important;
    text-transform: none;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    letter-spacing: -0.122283px;
    font-weight: 400;

    @include transition();

    .v-btn__content {
      line-height: inherit;
    }

    @include up($sm) {
      height: 76px !important;
      // width: 300px !important;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.15px;

      .v-btn__content {
        position: relative;
        top: -1px;
      }
    }

    @include up($md) {
      // width: 356px;
    }
  }
}
</style>