<template>
	<section class="home-form-b relative z-index-1" :class="{ final: final }">
		<v-container class="relative">
			<div
					class="
          home-form-b__bg
          pt-7
          pb-5
          px-3 px-sm-6 px-md-10
          py-md-7
          pt-sm-5
          pb-6 pb-sm-10
          rounded
          relative
          z-index-2
        "
			>
				<v-row>
					<!-- TITLE -->
					<v-col
							v-if="!final"
							cols="12"
							order-md="1"
							class="pb-md-14 d-none d-sm-block"
					>
						<h2 class="text-left text-sm-left pt-sm-12 pt-md-0">
							Бесплатный<br class="d-md-none"/>
							онлайн-фестиваль для&nbsp;родителе<span @click.ctrl="fillForm"
						>й</span
						>
						</h2>
					</v-col>

					<!-- CARDS -->
					<v-col v-if="final" cols="12" sm="7" class="pl-md-10">
						<v-row>
							<v-col cols="12" md="12" class="pt-4 pb-md-5">
								<h2
										class="
                    home-form-b__title
                    text-left text-sm-left
                    pt-sm-5 pt-md-0
                    size-h2
                  "
								>
									Запишитесь
									<span class="d-none d-sm-inline d-md-none"><br/></span>
									<span class="d-none d-sm-inline">прямо</span> сейча<span
										@click.ctrl="fillForm"
								>с</span
								>
								</h2>
							</v-col>
						</v-row>
					</v-col>
					<v-col v-else cols="12" sm="6" md="12" order-md="3">
						<v-row>
							<v-col cols="12" class="pt-4 d-sm-none">
								<h2 class="text-left text-sm-left">
									Бесплатный<br/>
									онлайн-фестиваль для родителе<span @click.ctrl="fillForm"
								>й</span
								>
								</h2>
							</v-col>
							<template>
								<v-col
										cols="12"
										md="4"
										class="pt-0 pb-1 pt-sm-9 pt-md-7 pb-md-10"
								>
									<div class="d-flex align-start">
										<img
												src="@/assets/home/home-form/fire-2x.png"
												alt="fire"
												class="home-form-b__img d-md-none"
										/>
										<img
												src="@/assets/home/home-form/chat-2x.png"
												alt="chat"
												class="home-form-b__img d-none d-md-block"
										/>
										<div class="size1 pl-1 pl-sm-2">
											Ответим на<br class="d-none d-md-inline"/>
											актуальные вопросы<br class="d-none d-md-inline"/>
											об&nbsp;образовании
										</div>
									</div>
								</v-col>
								<v-col
										cols="12"
										md="4"
										class="
                    pt-1
                    pb-3
                    py-sm-5
                    pt-md-7
                    pb-md-10
                    pl-md-0
                    pr-md-0
                    home-form-b__text2
                  "
								>
									<div class="d-flex">
										<img
												src="@/assets/home/home-form/heart-2x.png"
												alt="heart"
												class="home-form-b__img d-none d-md-block"
										/>
										<img
												src="@/assets/home/home-form/prize-2x.png"
												alt="prize"
												class="home-form-b__img d-md-none"
										/>
										<div class="size1 pl-1 pl-sm-2">
											Объясним, как не ссориться<br
												class="d-none d-sm-inline"
										/>
											из-за учёбы и&nbsp;справляться<br
												class="d-none d-sm-inline"
										/>
											со&nbsp;стрессом
										</div>
									</div>
								</v-col>
								<v-col
										cols="12"
										md="4"
										class="
                    d-none d-sm-block
                    pt-1 pt-md-7
                    pb-3 pb-md-10
                    px-md-0
                    home-form-b__text3
                  "
								>
									<div class="d-flex">
										<img
												src="@/assets/home/home-form/rocket-2x.png"
												alt="rocket"
												class="home-form-b__img"
										/>
										<!-- <img
																src="@/assets/home/home-form-b/heart-2x.png"
																alt="heart"
																class="home-form-b__img d-md-none"
															/> -->
										<div class="size1 pl-1 pl-sm-2">
											Обсудим, к какому<br/>будущему готовиться —<br/>
											и как именно
										</div>
									</div>
								</v-col>
							</template>
						</v-row>
					</v-col>

					<!-- FORM -->
					<v-col
							v-if="final"
							сols="12"
							sm="5"
							md="5"
							class="pt-sm-11 pl-sm-0 pt-md-3 pr-md-13 pl-md-16"
					>
						<v-form @submit.prevent="submit" class="relative z-index-1">
							<v-row>
								<v-col
										cols="12"
										sm="12"
										md="12"
										class="
                    py-1
                    pb-sm-2
                    pt-sm-6 pt-md-3
                    pb-md-1
                    pr-md-0
                    mt-md-5
                    pl-md-2
                  "
								>
									<v-text-field
											label="Имя"
											required
											solo
											hide-details
											flat
											maxlength="128"
											v-model="form.name"
											@focus="onFormFocus"
									/>
								</v-col>
								<v-col
										cols="12"
										sm="12"
										md="12"
										class="py-1 pt-md-1 pr-md-0 pb-md-1 pl-md-2"
								>
									<v-text-field
											label="Почта"
											required
											solo
											hide-details
											flat
											maxlength="128"
											v-model="form.email"
											@focus="onFormFocus"
									/>
								</v-col>
								<v-col
										cols="12"
										sm="12"
										md="12"
										class="py-1 py-sm-2 pt-md-1 pb-md-1 pr-md-0 pl-md-2"
								>
									<v-text-field
											label="+7 (999) 999-99-99"
											required
											solo
											hide-details
											flat
											:maxlength="phoneMaxlength"
											v-model="form.phone"
											@focus="onFormFocus"
											@keydown="filterPhoneKey"
											@keyup="fixPhone"
									/>
								</v-col>
								<v-col
										cols="12"
										sm="12"
										md="12"
										class="pt-1 pt-sm-2 pb-3 py-md-1 pr-md-0 pl-md-2"
								>
									<v-checkbox
											label="Ребёнок учится в Skysmart"
											required
											:ripple="false"
											class="home-form-b__checkbox-color ma-0 pa-0"
											hide-details
											v-model="form.isStudent"
											@focus="onFormFocus"
									>
										<!-- <template v-slot:label>
																<span class="d-flex justify-start align-center">
																	<svg v-if="form.isStudent" xmlns="http://www.w3.org/2000/svg"
																		 viewBox="0 0 24 24">
																		<path
																			d="M11 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597zm11-15v20h-20v-20h20zm2-2h-24v24h24v-24z"/>
																	</svg>
																	<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
																		d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"/></svg>
																	<span class="ml-2">Ребёнок учится в Skysmart</span>
																</span>
															</template> -->
									</v-checkbox>
								</v-col>
								<v-col
										cols="12"
										sm="12"
										md="12"
										class="pt-0 pt-sm-1 pb-2 py-md-1 pr-md-0 pl-md-2"
								>
									<v-btn
											block
											:ripple="false"
											elevation="0"
											type="submit"
											class="btn-size"
											@focus="onFormFocus"
									>
										Участвовать бесплатно
									</v-btn>
								</v-col>
								<v-col
										cols="12"
										sm="12"
										md="12"
										class="pt-0 pt-sm-2 pb-3 py-md-1 pr-md-0 pb-md-5 pl-md-2"
								>
									<p class="mb-0 text-center text-sm-left size-xs-1">
										Оставляя заявку,
										вы принимаете <br class="d-none d-sm-inline d-md-none"/>
										<router-link :to="{ name: 'rules' }" target="_blank"
										>оферту<br class="d-none d-md-inline"/> и соглашениe<br class="d-none d-sm-inline d-md-none"/>
											о конфиденциальности
										</router-link>
									</p>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
					<v-col
							v-else
							сols="12"
							sm="6"
							md="12"
							class="pt-sm-9 pt-md-3 pl-sm-3 pl-md-3"
							order-md="2"
					>
						<!--<img
														v-if="!final"
														src="@/assets/home/home-form-b/SmartFest.svg"
														alt="SmartFest"
														class="home-form-b__logo d-none d-sm-inline d-md-none mt-5"
												/>-->

						<v-form @submit.prevent="submit" class="relative z-index-1">
							<v-row>
								<v-col
										cols="12"
										sm="12"
										md="3"
										class="py-1 pb-sm-2 pt-sm-6 pt-md-0 pr-md-1 pb-md-1"
								>
									<v-text-field
											label="Имя"
											required
											solo
											hide-details
											flat
											maxlength="128"
											v-model="form.name"
											@focus="onFormFocus"
									/>
								</v-col>
								<v-col
										cols="12"
										sm="12"
										md="3"
										class="py-1 pt-md-0 px-md-1 pb-md-1"
								>
									<v-text-field
											label="Почта"
											required
											solo
											hide-details
											flat
											maxlength="128"
											v-model="form.email"
											@focus="onFormFocus"
									/>
								</v-col>
								<v-col
										cols="12"
										sm="12"
										md="3"
										class="py-1 py-sm-2 pt-md-0 pl-md-1 pb-md-1"
								>
									<v-text-field
											label="+7 (999) 999-99-99"
											required
											solo
											hide-details
											flat
											:maxlength="phoneMaxlength"
											v-model="form.phone"
											@focus="onFormFocus"
											@keydown="filterPhoneKey"
											@keyup="fixPhone"
									/>
								</v-col>
								<v-col
										cols="12"
										sm="12"
										md="3"
										class="pt-1 pt-sm-2 pt-md-3 pb-3"
										order-md="last"
								>
									<v-checkbox
											label="Ребёнок учится в Skysmart"
											required
											:ripple="false"
											class="home-form-b__checkbox-color ma-0 pa-0"
											hide-details
											v-model="form.isStudent"
											@focus="onFormFocus"
									>
										<!-- <template v-slot:label>
																<span class="d-flex justify-start align-center">
																	<svg v-if="form.isStudent" xmlns="http://www.w3.org/2000/svg"
																		 viewBox="0 0 24 24">
																		<path
																			d="M11 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597zm11-15v20h-20v-20h20zm2-2h-24v24h24v-24z"/>
																	</svg>
																	<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
																		d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"/></svg>
																	<span class="ml-2">Ребёнок учится в Skysmart</span>
																</span>
															</template> -->
									</v-checkbox>
								</v-col>
								<v-col
										cols="12"
										sm="12"
										md="3"
										class="pt-0 pt-sm-1 pt-md-0 pb-2 pb-md-1"
								>
									<v-btn
											block
											:ripple="false"
											elevation="0"
											type="submit"
											@focus="onFormFocus"
									>
										Участвовать бесплатно
									</v-btn>
								</v-col>
								<v-col
										cols="12"
										sm="12"
										md="9"
										class="pt-0 pt-sm-2 pt-md-3 pb-3"
								>
									<p class="mb-0 text-center text-sm-left size-xs">
										<router-link :to="{ name: 'rules' }" target="_blank"
										>Оставляя заявку, вы принимаете оферту и соглашениe о
											конфиденциальности
										</router-link>
									</p>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
				</v-row>
				<v-img
						v-if="final"
						src="@/assets/home/home-form-b/down/xs/2x.png"
						class="home-form-b__clip--2 d-sm-none"
				/>
				<v-img
						v-else
						src="@/assets/home/home-form-b/xs/2x.png"
						class="home-form-b__clip d-sm-none"
				/>

				<v-img
						v-if="final"
						src="@/assets/home/home-form-b/down/sm/2x.png"
						class="home-form-b__clip--2 d-none d-sm-block d-md-none"
				/>
				<v-img
						v-else
						src="@/assets/home/home-form-b/sm/2x.png"
						class="home-form-b__clip d-none d-sm-block d-md-none"
				/>

				<v-img
						v-if="final"
						src="@/assets/home/home-form-b/down/md/2x.png"
						class="home-form-b__clip--2 d-none d-md-block"
				/>
				<v-img
						v-else
						src="@/assets/home/home-form-b/md/2x.png"
						class="home-form-b__clip d-none d-md-block"
				/>
			</div>
			<div
					v-if="final"
					class="home-form-b__under-bg--2 rounded d-none d-md-block"
			></div>
			<div v-else class="home-form-b__under-bg rounded"></div>
		</v-container>

		<v-dialog v-model="isErrorDialog" width="500">
			<v-card>
				<v-card-title class="justify-center">Поправьте форму</v-card-title>

				<v-card-text class="justify-center text-center size-sm-md">
					{{ error }}
				</v-card-text>

				<v-card-actions class="justify-center">
					<v-btn
							text
							@click="
              error = null;
              isErrorDialog = null;
            "
					>
						Ок
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="isSuccessDialog" width="500">
			<v-card>
				<v-card-title class="justify-center"> Заявка отправлена</v-card-title>

				<v-card-text class="justify-center text-center size-sm-md">
					Успешный запроса на рассылку.
				</v-card-text>

				<v-card-actions class="justify-center">
					<v-btn text @click="isSuccessDialog = null"> Ок</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</section>
</template>

<script>
	import HomeDate from "@/components/Home/home-date";
	import {event} from "@/utils/gtm";
	import Config from "@/config";

	export default {
		components: {
			HomeDate,
		},
		props: {
			final: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				form: {
					name: "",
					email: "",
					phone: "",
					isStudent: false,
					url: null,
					isGroupB: true
				},
				isLoading: false,
				isErrorDialog: false,
				isSuccessDialog: false,
				error: null,
				phoneMaxlength: 32,
			};
		},

		watch: {
			error(val, old) {
				if (val && !old) {
					this.isErrorDialog = true;
					event({
						event: "_leads_form_sent_fail",
						formId: this.formId,
						errorMessage: val,
					});
				} else if (!val && old) this.isErrorDialog = false;
			},
			"form.phone"(val, old) {
				if (val !== old) {
					this.fixPhone();
				}
			},
		},
		computed: {
			formId() {
				return this.final ? "home-bottom" : "home-top";
			},
		},
		methods: {
			onFormFocus() {
				if (!this.$store.state.isHomeFormInteracted) {
					event({
						event: "_leads_form_start_interaction",
						formId: this.formId,
					});
					this.$store.state.isHomeFormInteracted = true;
				}
			},
			submit() {
				console.log("Submitting...");

				event({
					event: "_leads_form_submit",
					formId: this.formId,
				});

				const reName = /^.{2,}$/;
				if (!reName.test(this.form.name)) {
					return (this.error = "Пожалуйста, введите Ваше имя.");
				}

				const reEmail = /^.+?@.+?\..+$/;
				if (!reEmail.test(this.form.email)) {
					return (this.error =
						"Пожалуйста, укажите Ваш адрес электронной почты.");
				}

				const phone = this.form.phone.replace(/\D+/g, "");
				//console.log("Phone: "+phone);
				const rePhone = /^\d{10,24}$/;
				if (!rePhone.test(phone)) {
					return (this.error = "Пожалуйста, укажите Ваш номер телефона.");
				}

				this.form.url = self.location.href;

				this.error = null;
				this.result = null;
				this.isSuccessDialog = false;
				this.isLoading = true;

				//console.log("UTM", this.$route.query);
				//return;

				return this.$store
					.dispatch("post", {
						action: "SubmitController",
						params: this.form,
					})
					.then((res) => {
						console.log("RESULT: ", res);

						// remember entered values
						this.$store.state.homeForm = this.form;
						this.$store.state.homeFormResult = res;

						if (!this.$store.state.isHomeFormSubmitted) {
							event({
								event: "_leads_form_sent_success",
								formId: this.formId,
								subscribe_to: "action.grants.is_subscribed",
								//isBump: "true",
							});
							this.$store.state.isHomeFormSubmitted = true;
						}

						// change query
						const query = this.$route.query;
						if ( query['source_type'] === Config.UTM_SOURCE_TYPE_CORPORATE ) {
							// override for B2B
							delete query['utm_promocode'];
							query['promocode'] = Config.CORPORATE_UTM_PROMOCODE;
						}

						//this.isSuccessDialog = true;
						const who = this.form.isStudent ? "student" : "newcomer";
						this.$router.push({
							name: "lesson",
							params: {who},
							query: {
								...query,
								_submitId: res.SubmitController?.submitId,
							},
						});
					})
					.catch((error) => {
						this.error = res?.error ? res?.error : "Не получилось...";
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			fillForm() {
				this.form.name = "tstprod";
				this.form.email = "test@selfbooking.ru";
				this.form.phone = "+79221996764";
				this.form.isStudent = true;
			},
			filterPhoneKey(e) {
				//console.log(e);
				const a = e.key;

				// ignore arrows, del, backspace
				if (
					e.keyCode === 37 ||
					e.keyCode === 39 ||
					e.keyCode === 8 ||
					e.keyCode === 46 ||
					e.keyCode === 35 ||
					e.keyCode === 36 ||
					e.keyCode === 9 ||
					(e.key === "x" && e.ctrlKey) ||
					(e.key === "c" && e.ctrlKey) ||
					(e.key === "v" && e.ctrlKey)
				)
					return true;

				if (!this.validatePhoneKey(a)) e.preventDefault();
			},
			validatePhoneKey(a) {
				if (!a.match(/[+0-9\(\) -]/)) return false;

				let l = this.form.phone?.length;
				//console.log("a: "+a+", l: "+l);
				if (l === 0) {
					if (!a.match(/[+0-9]/)) return false;
					if (a === "+") this.form.phone = "";
					else if (a !== "8") this.form.phone = "+";
					else if (a === "9") this.form.phone = "+7 (";
				} else if (l === 1) {
					if (this.form.phone === "+" && a === "8") this.form.phone = "";
				}
				return true;
			},
			fixPhone(e) {
				if (
					e &&
					(e.keyCode === 37 ||
						e.keyCode === 39 ||
						e.keyCode === 8 ||
						e.keyCode === 46 ||
						e.keyCode === 35 ||
						e.keyCode === 36)
				)
					return true;

				let s = this.form.phone;
				s = s.replace(/[^0-9]+/g, "");
				//console.log("s=" + s);
				//v = v.substr(1);

				this.phoneMaxlength = 32;
				if (!s.length) return "";
				const f = [];
				for (let i = 0; i < s.length; i++) {
					let l = s[i];
					//if ( i === 0 && l === "+" )
					//console.log(i + "=" + l);

					if (s[0] === "8") {
						// 8 ...
						this.phoneMaxlength = 17;
						if (i === 1) {
							f.push(" ");
							f.push("(");
						} else if (i === 4) {
							f.push(")");
							f.push(" ");
						} else if (i === 7) {
							f.push("-");
						} else if (i === 9) {
							f.push("-");
						}
						f.push(l);
					} else if (s[0] === "7") {
						// +7 ...
						this.phoneMaxlength = 18;
						if (i === 0) {
							f.push("+");
						} else if (i === 1) {
							f.push(" ");
							f.push("(");
						} else if (i === 4) {
							f.push(")");
							f.push(" ");
						} else if (i === 7) {
							f.push("-");
						} else if (i === 9) {
							f.push("-");
						}
						f.push(l);
					} else if (s[0] === "9") {
						// 9 ...
						this.phoneMaxlength = 18;
						if (i === 0) {
							f.push("+");
							f.push("7");
							f.push(" ");
							f.push("(");
						} else if (i === 4) {
							f.push(")");
							f.push(" ");
						} else if (i === 7) {
							f.push("-");
						} else if (i === 9) {
							f.push("-");
						}
						f.push(l);
					} else {
						// [0-6] ...
						this.phoneMaxlength = 24;
						if (i === 0) {
							f.push("+");
						} else if (i === 3) {
							f.push(" ");
						}
						f.push(l);
					}
				}
				const p = f.join("");
				if (p === this.form.phone) return;
				this.form.phone = p;
			},
		},
		mounted() {
			// todo add real visibility test
			if (!this.final && !this.$store.state.isHomeFormVisibilitySubmitted) {
				// need some pause because GTM is delayed
				setTimeout(() => {
					event({
						event: "_leads_form_in_viewport",
						formId: this.formId,
					});
				}, Config.GTM_DELAY + 1500);
				this.$store.state.isHomeFormVisibilitySubmitted = true;
			}
		},
	};
</script>

<style lang="scss">
	.home-form-b {
		color: $white;

		&__clip {
			position: absolute;
			width: 259px;
			top: -47px;
			right: -23px;
			@include up($sm) {
				width: 406px;
				top: -11px;
				right: -29px;
			}
			@include up($md) {
				width: 435px;
				top: 80px;
				right: -30px;
			}

			&--2 {
				position: absolute;
				// width: 302px;
				width: 100%;
				top: 71px;
				left: -7%;
				@include up($sm) {
					width: 439px;
					top: unset;
					bottom: -20px;
					left: -29px;
				}
				@include up($md) {
					width: 489px;
					top: unset;
					bottom: -21px;
					left: 116px;
				}
			}
		}

		&__under-bg {
			position: absolute;
			background: $white;
			transform: rotate(-2deg);
			height: 95%;
			width: 95%;
			top: 5px;
			left: 10px;
			@include up($sm) {
				top: 10px;
				left: 35px;
				width: 91%;
				height: 100%;
			}
			@include up($md) {
				top: 10px;
				left: 135px;
				width: 81%;
				height: 97%;
			}

			&--2 {
				@include up($md) {
					position: absolute;
					background: $white;
					transform: rotate(-2deg);
					top: 10px;
					left: 140px;
					width: 81%;
					height: 95%;
				}
			}
		}

		&__bg {
			// overflow: hidden;
			background: $blue-gradient;
		}

		h2 {
			font-size: 30px;
			line-height: 30px;
			letter-spacing: -1.2px;
			@include up($sm) {
				font-size: 72px;
				line-height: 72px;
				letter-spacing: 0;
			}
		}

		&__title {
			margin-bottom: 51%;
			@include up($sm) {
				margin-bottom: unset;


			}
		}

		&__logo {
			width: 85px;
			@include up($sm) {
				width: 133px;
				//height: 30px;
			}
		}

		&__text {
			&2 {
				@include up($md) {
					position: relative;
					left: -50px;
				}
			}

			&3 {
				@include up($md) {
					position: relative;
					left: -20px;
				}
			}
		}

		&__img {
			width: 22px;
			height: 22px;

			@include up($sm) {
				width: 30px;
				height: 30px;
			}
		}

		a {
			color: $white !important;
			opacity: 0.7;
			@include transition;

			&:hover {
				opacity: 1;
			}
		}

		&.final {
			.home-form-b__logo {
				@include up($sm) {
					width: 172px;
				}
			}

			.home-form-b__date {
				font-size: 16px;
				line-height: 18px;
				text-align: right;
				letter-spacing: -0.308697px;

				@include up($sm) {
					font-size: 40px;
					line-height: 44px;
					letter-spacing: -0.6px;
				}
			}
		}

		.size-xs-1 {
			font-size: 12px;
			line-height: 12px;
			letter-spacing: -0.07px;

			@include up($sm) {
				font-size: 16px;
				line-height: 20px;
				letter-spacing: -0.1px;
			}
			@include up($md) {
				font-size: 14px;
				line-height: 16px;
				letter-spacing: -0.3px;
			}
		}

		.size-h2 {
			font-size: 30px;
			line-height: 30px;
			letter-spacing: -0.15px;
			@include up($sm) {
				font-size: 64px;
				line-height: 72px;
				letter-spacing: -0.8px;
			}
			@include up($md) {
				font-size: 64px;
				line-height: 66px;
				letter-spacing: -0.8px;
			}
		}

		.v-form {
			.btn-size {
				@include up($sm) {
					height: 52px !important;
				}
			}

			// .v-input--checkbox {
			// 	.v-input--selection-controls__input {
			// 		width: 0;
			// 	}
			// 	.v-icon {
			// 		display: none;
			// 	}
			// 	svg {
			// 		width: 16px;
			// 		height: 16px;

			// 	}
			// }
		}

		&__checkbox-color {
			.v-input--checkbox,
			.v-label {
				color: $white !important;
				font-size: 16px !important;
				line-height: 18px !important;
				white-space: nowrap;
				@include up($sm) {
					font-size: 18px !important;
					line-height: 26px !important;
					letter-spacing: -0.05px !important;
				}
				@include up($md) {
					// line-height: 24px !important;
					letter-spacing: 0 !important;

				}
			}

			.v-icon {
				color: $white !important;
			}
		}
	}
</style>
