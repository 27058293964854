<template>
  <section class="home-speakers">
    <v-container class="pt-0 z-index-1 relative">
      <h2 class="z-index-1 mb-md-5">
        В<span :title="groupName">е</span>сь день на ваши вопросы<br class="d-none d-md-inline" />
        отвечают топ-эксперты в&nbsp;образовании<br
          class="d-none d-sm-inline d-md-none"
        />
        и&nbsp;воспитании
      </h2>
      <div class="home-speakers__grid">
        <template v-if="isWindowMD">
          <div class="el el--3">
            <home-speakers-card-3 />
          </div>
          <div class="el el--1">
            <home-speakers-card-1 />
          </div>
          <div class="el el--6">
            <home-speakers-card-6 />
          </div>

          <div class="el el--5">
            <home-speakers-card-5 />
          </div>
          <div class="el el--2 relative">
            <home-speakers-card-2 class="relative z-index-1" />

            <v-img
              class="home-speakers__bg--2"
              contain
              src="@/assets/home/home-speakers/curl-3.svg"
            />
          </div>
          <div class="el el--4">
            <home-speakers-card-4 />
          </div>
										          
					<div class="el el--3">
            <home-speakers-card-7 />
          </div>
        </template>
        <template v-else-if="isWindowSM && !isWindowMD">
          <div class="el el--1">
            <home-speakers-card-1 />
          </div>
          <div class="el el--5">
            <home-speakers-card-5 />
          </div>
          <div class="el el--3">
            <home-speakers-card-3 />
          </div>
          <div class="el el--6">
            <home-speakers-card-6 />
          </div>
          <div class="el el--2">
            <home-speakers-card-2 />
          </div>
          <div class="el el--3">
            <home-speakers-card-7 />
          </div>
          <div class="el el--4">
            <home-speakers-card-4 />
          </div>
        </template>
        <template v-else>
          <div class="el el--1">
            <home-speakers-card-1 />
          </div>
          <div class="el el--5">
            <home-speakers-card-5 />
          </div>
          <div class="el el--2">
            <home-speakers-card-2 />
          </div>
          <div class="el el--3">
            <home-speakers-card-3 />
          </div>
          <div class="el el--3">
            <home-speakers-card-7 />
          </div>
          <div class="el el--4">
            <home-speakers-card-4 />
          </div>
          <div class="el el--6">
            <home-speakers-card-6 />
          </div>
        </template>

        <br clear="all" />

        <!-- <v-img class="home-speakers__hero d-none d-sm-block" src="@/assets/home/home-speakers/hero.png"
							   alt="Hero"/> -->
        <v-img
          class="home-speakers__hero--2 d-none d-sm-block"
          src="@/assets/home/home-speakers/man-2x.png"
        />
      </div>
    </v-container>

    <!-- <v-img class="home-speakers__bg" contain src="@/assets/home/home-speakers/curl.svg" /> -->
    <v-img
      class="home-speakers__bg"
      contain
      src="@/assets/home/home-speakers/curl-2.svg"
    />
  </section>
</template>

<script>
import HomeSpeakersCard1 from "@/components/Home/home-speakers-card1";
import HomeSpeakersCard2 from "@/components/Home/home-speakers-card2";
import HomeSpeakersCard3 from "@/components/Home/home-speakers-card3";
import HomeSpeakersCard4 from "@/components/Home/home-speakers-card4";
import HomeSpeakersCard5 from "@/components/Home/home-speakers-card5";
import HomeSpeakersCard6 from "@/components/Home/home-speakers-card6";
import HomeSpeakersCard7 from "@/components/Home/home-speakers-card7";

export default {
  components: {
    HomeSpeakersCard1,
    HomeSpeakersCard2,
    HomeSpeakersCard3,
    HomeSpeakersCard4,
    HomeSpeakersCard5,
    HomeSpeakersCard6,
    HomeSpeakersCard7,
  },
  computed: {
    isWindowSM() {
      return this.$store.getters.isWindowSM;
    },
    isWindowMD() {
      return this.$store.getters.isWindowMD;
    },
    groupName() {
      return this.$store.state.isGroupB ? "B" : "A";
    }
  },
  data: () => {
    return {};
  },
};
</script>

<style lang='scss'>
.home-speakers {
  position: relative;

  &__grid {
    position: relative;
    margin-top: 4px;

    @include up($sm) {
      margin-top: 30px;
    }

    @include up($md) {
      margin-top: 0;
    }

    .el {
      position: relative;
      z-index: 1;
      margin-top: 12px;
      box-sizing: border-box;

      @include up($sm) {
        margin-top: 24px;
        padding-right: 12px;
        float: left;
        width: 50%;

        &--5 {
          padding-top: 170px;
        }
        &--4,
        &--5,
        &--6 {
          float: right;
          padding-left: 12px;
        }
      }

      @include up($md) {
        margin-top: 32px;
        padding-left: 0;
        padding-right: 16px;
        float: left;
        width: 33.33%;

        &--1,
        &--4,
        &--5 {
          padding-left: 16px;
          padding-right: 16px;
        }
        &--2 {
          //float: right;
          padding-left: 0;
          padding-right: 16px;
        }
				&--4 {
					float: right
				}
        &--5 {
          padding-top: 0;
          float: right;
        }
        &--3 {
          padding-top: 0;
        }
        &--6 {
          float: right;
          padding-top: 275px;
          padding-left: 16px;
          padding-right: 0;
        }

      }
    }
  }

  &__hero {
    position: absolute;
    top: -120px;
    right: 10%;
    max-width: 210px;
    //max-width: 237px;
    //width: 104%;

    @include up($md) {
      top: -30px;
      right: 5%;
    }

    &--2 {
      @include up($sm) {
        top: -135px;
        left: 77%;
        transform: translateX(-50%);
        position: absolute;
        width: 530px;
      }
      @include up($md) {
        top: -205px;
        left: 85%;
        width: 623px;
      }
    }
  }

  // commonly used in all cards
  &__img {
    max-width: 22px;
    max-height: 22px;

    @include up($sm) {
      max-width: 30px;
      max-height: 30px;
    }
  }

  &__bg {
    display: none;

    @include up($md) {
      display: block;
      position: absolute;
      left: -20px;
      right: 0;
      margin: 0 auto;
      top: -100px;
      // height: 1122px;
      width: 100%;
      max-width: 1260px;
    }

    &--2 {
      display: none;

      @include up($md) {
        position: absolute;
        display: block;
        right: 10%;
        top: 0;
        width: 482px;
        height: 574px;
      }
    }
  }
}
</style>