<template>
	<home-speakers-card :items="speakers" bg="1" class="--4">
		<h3>Школа сегодня:<br/>что ожидать и как<br/>ориентироваться</h3>
	</home-speakers-card>
</template>

<script>
    import HomeSpeakersCard from "@/components/Home/home-speakers-card";

    export default {
        components: {
            HomeSpeakersCard,
        },
        data: () => {
            return {
                speakers: [
                    {
                        name: "Дима Зицер",
                        image: require("@/assets/speakers/original/speaker-dz.png"),
                    },
                    {
                        name: "Александр Ларьяновский",
                        image: require("@/assets/speakers/original/speaker-al-l.png"),
                    },
                    /*{
                        name: "Екатерина Шрейнер",
                        image: require("@/assets/speakers/s3.png")
                    }*/
                ],
            };
        },
    };
</script>

<style lang='scss'>
	.home-speakers-card.--4 {
		.home-speakers-card {
			&__names {
				justify-content: center !important;
				column-gap: 50px;
				transform: translateX(3%);

				@include up($sm) {
					column-gap: 40px;
					transform: translateX(7%);
				}
			}

			&__images {
				height: 130px;
				max-width: 320px;

				& > div {
					&:nth-child(1) {
						transform: translateX(26%);
						z-index: 0;
					}

					&:nth-child(2) {
						transform: translateX(-10%);
					}
				}

				@include up($sm) {
					//height: 140px;
					//max-width: 320px;
				}
			}
		}
	}
</style>