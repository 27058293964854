<template>
  <section class="home-comments">
    <v-container>
      <h2 class="mb-6 mb-sm-13 mb-md-16">
        Отзывы участников прошлых фестивалей расскажут<br class="d-sm-none"/> о SmartFest лучше нас
      </h2>
      <div class="home-comments__board">
        <div
          class="
            home-comments-card
            home-comments__item
            mb-3 mb-sm-6
            rounded
            pa-5
            d-none d-sm-block d-md-none
          "
        >
          <div class="home-comments-card__author mb-4">
            <div class="d-flex">
              <div class="home-comments-card__author-avatar mr-3">
                <v-img src="@/assets/home/home-comments/a.png" />
              </div>
              <div class="home-comments-card__author-name">Альбина</div>
            </div>
            <div class="home-comments-card__author-stars d-flex">
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
            </div>
          </div>
          <div class="home-comments-card__text">
            Просто отлично! Я восхищаюсь. Нет слов, чтобы описать.
          </div>
        </div>
        <home-counter class="home-comments__item d-none d-sm-block d-md-none mb-sm-6" />
        <div
          class="
            home-comments-card
            home-comments__item
            mb-4 mb-sm-6
            rounded
            pa-5
          "
        >
          <div class="home-comments-card__author mb-4">
            <div class="d-flex">
              <div class="home-comments-card__author-avatar mr-3">
                <v-img src="@/assets/home/home-comments/ks.png" />
              </div>
              <div class="home-comments-card__author-name">Ксения</div>
            </div>
            <div class="home-comments-card__author-stars d-flex">
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-2.svg" />
            </div>
          </div>
          <div class="home-comments-card__text">
            Было очень классно и информативно! Слова спикеров очень
            замотивировали меня, хочется покорить ВЕСЬ МИР:) Спасибо большое!
            Устраивайте такие мероприятия почаще.
          </div>
        </div>
        <div
          class="
            home-comments-card
            home-comments__item
            mb-3 mb-sm-6
            rounded
            pa-5
            d-none d-sm-block d-md-none
          "
        >
          <div class="home-comments-card__author mb-4">
            <div class="d-flex">
              <div class="home-comments-card__author-avatar mr-3">
                <v-img src="@/assets/home/home-comments/p.png" />
              </div>
              <div class="home-comments-card__author-name">Полина</div>
            </div>
            <div class="home-comments-card__author-stars d-flex">
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-2.svg" />
            </div>
          </div>
          <div class="home-comments-card__text">
            Эфир потрясающий! Я преподаватель с 30-летним стажем, получила море
            положительных эмоций!
          </div>
        </div>
        <div
          class="
            home-comments-card
            home-comments__item
            mb-3 mb-sm-6
            rounded
            pa-5
            d-none d-md-block
          "
        >
          <div class="home-comments-card__author mb-4">
            <div class="d-flex">
              <div class="home-comments-card__author-avatar mr-3">
                <v-img src="@/assets/home/home-comments/l.png" />
              </div>
              <div class="home-comments-card__author-name">Лариса</div>
            </div>
            <div class="home-comments-card__author-stars d-flex">
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
            </div>
          </div>
          <div class="home-comments-card__text">
            Спасибо огромное за информацию и возможность в очередной раз
            задуматься о будущем своих детей!
          </div>
        </div>
        
        <div
          class="
            home-comments-card
            home-comments__item
            mb-4 mb-sm-6
            rounded
            pa-5
          "
        >
          <div class="home-comments-card__author mb-4">
            <div class="d-flex">
              <div class="home-comments-card__author-avatar mr-3">
                <v-img src="@/assets/home/home-comments/s.png" />
              </div>
              <div class="home-comments-card__author-name">Софья</div>
            </div>
            <div class="home-comments-card__author-stars d-flex">
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
            </div>
          </div>
          <div class="home-comments-card__text">
            Было клёво послушать людей, разбирающихся в теме.
          </div>
        </div>
        <div
          class="
            home-comments-card
            home-comments__item
            mb-3 mb-sm-6
            rounded
            pa-5
            d-none d-md-block
          "
        >
          <div class="home-comments-card__author mb-4">
            <div class="d-flex">
              <div class="home-comments-card__author-avatar mr-3">
                <v-img src="@/assets/home/home-comments/p.png" />
              </div>
              <div class="home-comments-card__author-name">Полина</div>
            </div>
            <div class="home-comments-card__author-stars d-flex">
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-2.svg" />
            </div>
          </div>
          <div class="home-comments-card__text">
            Эфир потрясающий! Я преподаватель с 30-летним стажем, получила море
            положительных эмоций!
          </div>
        </div>
        <div
          class="
            home-comments-card
            home-comments__item
            mb-3 mb-sm-6
            rounded
            pa-5
            d-none d-md-block
          "
        >
          <div class="home-comments-card__author mb-4">
            <div class="d-flex">
              <div class="home-comments-card__author-avatar mr-3">
                <v-img src="@/assets/home/home-comments/a.png" />
              </div>
              <div class="home-comments-card__author-name">Альбина</div>
            </div>
            <div class="home-comments-card__author-stars d-flex">
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
            </div>
          </div>
          <div class="home-comments-card__text">
            Просто отлично! Я восхищаюсь. Нет слов, чтобы описать.
          </div>
        </div>
        <div
          class="
            home-comments-card
            home-comments__item
            mb-4 mb-sm-6
            rounded
            pa-5
          "
        >
          <div class="home-comments-card__author mb-4">
            <div class="d-flex">
              <div class="home-comments-card__author-avatar mr-3">
                <v-img src="@/assets/home/home-comments/t.png" />
              </div>
              <div class="home-comments-card__author-name">Татьяна</div>
            </div>
            <div class="home-comments-card__author-stars d-flex">
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-2.svg" />
            </div>
          </div>
          <div class="home-comments-card__text">
            Эти эфиры должны смотреть все учителя!
          </div>
        </div>
        <div
          class="
            home-comments-card
            home-comments__item
            mb-3 mb-sm-6
            rounded
            pa-5
            d-none d-sm-block
          "
        >
          <div class="home-comments-card__author mb-4">
            <div class="d-flex">
              <div class="home-comments-card__author-avatar mr-3">
                <v-img src="@/assets/home/home-comments/k.png" />
              </div>
              <div class="home-comments-card__author-name">Катя</div>
            </div>
            <div class="home-comments-card__author-stars d-flex">
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
            </div>
          </div>
          <div class="home-comments-card__text">
            Большое спасибо) Смотрю и думаю не только о развитии ребёнка, но и о
            своём)
          </div>
        </div>
        <div
          class="
            home-comments-card
            home-comments__item
            mb-3 mb-sm-6
            rounded
            pa-5
            d-none d-sm-block d-md-none
          "
        >
          <div class="home-comments-card__author mb-4">
            <div class="d-flex">
              <div class="home-comments-card__author-avatar mr-3">
                <v-img src="@/assets/home/home-comments/l.png" />
              </div>
              <div class="home-comments-card__author-name">Лариса</div>
            </div>
            <div class="home-comments-card__author-stars d-flex">
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
              <v-img src="@/assets/home/stars/star-1.svg" />
            </div>
          </div>
          <div class="home-comments-card__text">
            Спасибо огромное за информацию и возможность в очередной раз
            задуматься о будущем своих детей!
          </div>
        </div>
                        <home-counter
          class="home-comments__item d-sm-none d-md-block relative z-index-3"
        />
      </div>
    </v-container>
  </section>
</template>

<script>
import HomeCounter from "@/components/Home/home-counter";

export default {
  components: {
    HomeCounter,
  },
};
</script>

<style lang="scss">
.home-comments {
  h2 {
    letter-spacing: -0.8px;
    @include up($md) {

      letter-spacing: -3px;
    }
  }
  &__board {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include up($sm) {
      flex-wrap: wrap;
      column-gap: 40px;
      max-height: 1150px;
      // overflow: hidden;
    }

    @include up($md) {
      max-height: 800px;
      width: 1161px;
    }
  }
  &__item {
    @include up($sm) {
      min-width: 350px;
      width: calc(100% / 2 - 40px / 2);
    }

    @include up($md) {
      width: 360px;
    }
  }

  .home-comments-card {
    background: linear-gradient(180deg, #ffd88a 0%, #fff4dd 85.8%);

    &__author {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 15px;

      &-avatar {
        background-color: #f2ffbd;
        border-radius: 50%;
        overflow: hidden;
        width: 39px;
        height: 39px;

        @include up($sm) {
          width: 48px;
          height: 48px;
        }
      }

      &-name {
        font-size: 20px;
        line-height: 14px;
        letter-spacing: -0.068153px;

        @include up($sm) {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.0825909px;
        }
      }

      &-stars {
        height: 12px;
        column-gap: 2px;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 25px;
      letter-spacing: -0.321665px;

      @include up($sm) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.1px;
      }
    }
  }
}
</style>