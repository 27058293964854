<template>
	<section class="lesson-screenshots">
		<v-container>
			<v-row>
				<v-col cols="12" class="pb-sm-7">
					<h2>
						В Skysmart<br class="d-sm-none"/>
						ребёнок точно<br class="d-none d-md-inline"/> полюбит учиться
					</h2>
				</v-col>
				<v-col cols="12" md="8" class="pt-0 mb-sm-5">
					<div class="lesson-screenshots__img">

						<v-img key="bg"
							   src="@/assets/lesson/lesson-screenshots/1.jpg"
							   alt="screenshot"
							   class="lesson-screenshots__img-item bg"
						/>

						<v-img key="0" :class="{prev:index===1,active:index===0}"
							   src="@/assets/lesson/lesson-screenshots/1.jpg"
							   alt="screenshot"
							   class="lesson-screenshots__img-item"
						/>
						<v-img key="1" :class="{prev:index===2,active:index===1}"
							   src="@/assets/lesson/lesson-screenshots/2.jpg"
							   alt="screenshot"
							   class="lesson-screenshots__img-item"
						/>
						<v-img key="2" :class="{prev:index===3,active:index===2}"
							   src="@/assets/lesson/lesson-screenshots/3.jpg"
							   alt="screenshot"
							   class="lesson-screenshots__img-item"
						/>
						<v-img key="3" :class="{prev:index===0,active:index===3}"
							   src="@/assets/lesson/lesson-screenshots/4.jpg"
							   alt="screenshot"
							   class="lesson-screenshots__img-item"
						/>

					</div>
				</v-col>
				<v-col cols="12" md="4" class="pt-0">
					<div class="lesson-screenshots__text mb-sm-7 mb-md-9">
						<p class="mb-4 mb-md-9">
							Школьные предметы<br class="d-sm-none d-md-inline"/>
							и занятия для<br class="d-none d-sm-inline d-md-none"/>
							дошколят<br class="d-none d-md-inline"/> на<br class="d-sm-none"/>
							интерактивной<br class="d-none d-md-inline"/> платформе
						</p>
						<p class="d-sm-none">
							Занимайтесь где угодно —<br class="d-sm-none"/>
							нужен только компьютер<br class="d-sm-none"/>
							и интернет
						</p>
						<p class="d-none d-sm-block">
							С гибким расписанием<br class="d-none d-md-inline"/> вы встроите<br
								class="d-none d-sm-inline d-md-none"/>
							уроки<br class="d-none d-md-inline"/> в любой график
						</p>
					</div>
					<v-form>
						<v-btn @click="scrollTo('form')" class="lesson-screenshots__btn d-none d-sm-block" elevation="0">Записаться</v-btn>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
    import LessonScreenshots from "@/components/Lesson/lesson-screenshots";
		import { scrollTo } from "@/utils/utils";

    export default {
        components: {
            LessonScreenshots,
        },
        data: () => {
            return {
                index: 0,
                interval: null
            }
        },
				methods: {
					scrollTo(el) {
						scrollTo(el);
					}
				},
        mounted() {
            this.interval = setInterval(() => {
                this.index++;
                if (this.index > 3) this.index = 0;
            }, 3000);
        },
        destroyed() {
            clearInterval(this.interval);
        },
				
    };
</script>

<style lang="scss">
	.lesson-screenshots {
		&__img {
			position: relative;
			border: 3px solid $golden;
			border-radius: 8px;
			overflow: hidden;
			//width: 100%;
			//max-width: 400px;
			//margin: 0 auto;
			//height: 200px;

			@include up($sm) {
				//width: 756px;
				//height: 450px;
			}

			.v-image {
				position: absolute;
				left: 0;
				top:0;
				height: 100%;
				width: 100%;
				opacity: 0;
				//@include transition-long();
				transition: opacity 1s;

				&.active, &.prev {
					opacity: 1;
				}
				&.prev {
					z-index: 1;
				}
				&.active {
					z-index: 2;
				}

				&.bg {
					position: static;
					width: 100%;
					height: auto;
				}
			}
		}

		&__text {
			font-size: 18px;
			line-height: 24px;
			letter-spacing: -0.05px;

			@include up($sm) {
				font-size: 28px;
				line-height: 110%;
			}
		}

		.v-form {
			.v-btn {
				height: 52px !important;
				width: 360px !important;
				border-radius: 10px;

				@include up ($sm) {
					height: 76px !important;
				}

				.v-btn__content {
					font-size: 24px;
					line-height: 33px;
					text-align: center;
					letter-spacing: -0.122283px;

					@include up($sm) {
						font-size: 32px;
						line-height: 40px;
						letter-spacing: -0.15px;
					}
				}
			}
		}
	}
</style>