<template>
	<home-speakers-card :items="speakers" bg="2" class="--2">
		<h3>Уроки, которых<br/>не было в школе</h3>
	</home-speakers-card>
</template>

<script>
	import HomeSpeakersCard from "@/components/Home/home-speakers-card";

	export default {
		components: {
			HomeSpeakersCard,
		},
		data: () => {
			return {
				speakers: [
					{
						name: "Александр Панчин",
						image: require("@/assets/speakers/original/speaker-ap.png"),
					},
					{
						name: "Екатерина Шрейнер",
						image: require("@/assets/speakers/original/speaker-esh.png"),
					},
					{
						name: "Галина Боб",
						image: require("@/assets/speakers/original/speaker-gb.png"),
					},
				],
			};
		},
	};
</script>

<style lang='scss'>
	.home-speakers-card.--2 {
		.home-speakers-card {
			&__names {
				max-width: 280px;

				@include up($sm) {
					max-width: 310px;
					& > div {
						&:nth-child(3) {
							transform: translateX(15%);
						}
					}
				}
				@include up($md) {
					max-width: 300px;
				}
			}

			&__images {
				height: 130px;
				max-width: 360px;

				& > div {
					&:nth-child(1) {
						width: 45%;
					}

					&:nth-child(2) {
						//width: 43%;
						transform: translateX(-45%);
					}

					&:nth-child(3) {
						transform: translateX(-5%);
					}
				}

				@include up($sm) {
					height: 150px;

					& > div {
						&:nth-child(1) {
							left: -3%;
							transform: translateX(-1%);
						}

						&:nth-child(2) {
							transform: translateX(-45%);
						}

						&:nth-child(3) {
							transform: translateX(-3%);
						}
					}
				}
			}
		}
	}
</style>