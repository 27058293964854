//import config from '../config'

let state = {

    response: {},	// объект ответа от БЕ в чистом виде
    session: {},	// данные текущей сессии
    common: {},			// общие для всех страниц
    page: {},			// индивидуальные для каждой страницы
    currentPage: {},	// текущая страница
    isLoading: false,

    // реактивные размеры экрана
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,

    isStudent: false,
    homeForm: null,
    homeFormResult: null,
    isGroupB: false,

    isHomeFormSubmitted: false,
    isHomeFormInteracted: false,
    isHomeFormVisibilitySubmitted: false,
    isLessonFormSubmitted: false,
    isLessonFormInteracted: false,
    isLessonFormVisibilitySubmitted: false,
};

export default state;
