export default {
    //in _variables.scss, config.js and plugins/vuetify.js
    WIDTH_SM: 834,
    WIDTH_MD: 1440,
    WIDTH_LG: 1441,
    WIDTH_XL: 1442,

    LESSON_SUBMIT_URL: "https://skygate.skyeng.ru/api/v1/proxy-kid/create",
    LESSON_SUBMIT_PROMOCODE: "fest",
    LESSON_SUBMIT_PRODUCT: "type-skysmart_festival|name-smartfest2022",
    UTM_SOURCE_TYPE_CORPORATE: "corporate",
    CORPORATE_UTM_PROMOCODE: "B2BFEST",


    STORAGE_GROUP_KEY: "IS_B_GROUP",

    GTM: "GTM-MBGZXZJ",
    GTM_DELAY: 1500,    // ms
}