<template>
	<section class="home-intro">
		<v-container class="pb-0">
			<v-row>
				<v-col cols="12" md="7" class="pb-0 pb-sm-2">
					<v-img src="@/assets/logo-2x.png" alt="SkySmart" class="home-intro__logo"/>
					<h1>
						<span class="golden">Бесплатный</span><br/>
						онлайн-фестиваль<br/>
						для <img src="@/assets/home/parents-2x.png" class="home-intro__parents d-none d-sm-inline" width="97" height="59"/>
						родителей
						<img src="@/assets/home/parents-2x.png" class="home-intro__parents d-sm-none" width="40" height="24"/>
					</h1>
					<home-date class="pt-3 pt-sm-8 pt-md-7"/>
				</v-col>
				<v-col cols="12" md="5" class="pt-2 pb-4 pt-sm-0 pt-md-2">
					<div class="home-intro__speakers">

						<div class="home-intro__speakers-item home-intro__speakers-item--1">
							<v-img src="@/assets/home/home-intro/zicer.2x.png"/>
							<div class="home-intro__speakers-name size-xs-sm">
								Дима<br/>Зицер
							</div>
							<!--<div class="home-intro__speakers-photo home-intro__speakers-photo&#45;&#45;2 rounded c-grad">

							</div>-->
						</div>

						<div class="home-intro__speakers-item order-sm-last"
							 :class="isWindowSM?'home-intro__speakers-item--3':'home-intro__speakers-item--2'">
							<v-img src="@/assets/home/home-intro/petranovskaya.2x.png"/>
							<div class="home-intro__speakers-name size-xs-sm">
								Людмила<br/>Петрановская
							</div>
							<!--<div class="home-intro__speakers-photo home-intro__speakers-photo&#45;&#45;3 rounded c-grad">

							</div>-->

							<div class="d-none d-sm-flex z-index-1 home-intro__speakers-more">
							<v-img src="@/assets/home/plus-2x.png" class="home-intro__speakers-img"></v-img>
							<div class="pl-1">И ещё 12<br/>спикеров</div>	
							</div>
						</div>

						<div class="home-intro__speakers-item"
							 :class="isWindowSM?'home-intro__speakers-item--2':'home-intro__speakers-item--3'">
							<v-img src="@/assets/home/home-intro/ponaroshku.2x.png"/>
							<div class="home-intro__speakers-name size-xs-sm">
								Ирена<br/>Понарошку
							</div>
							<!--<div class="home-intro__speakers-photo home-intro__speakers-photo&#45;&#45;1 rounded c-grad">

							</div>-->
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
    import HomeDate from "@/components/Home/home-date";

    export default {
        components: {
            HomeDate,
        },
        computed: {
            isWindowSM() {
                return this.$store.getters.isWindowSM;
            }
        }
    };
</script>


<style lang="scss">
	.home-intro {
		&__logo {
			width: 132px;
			height: 31px;
			position: relative;
			left: -5px;
			display: inline-block;

			@include up($sm) {
				width: 219px;
				height: 50px;
				left: -1px;
			}

			@include up($sm) {
				margin-bottom: 12px;
			}

			/*@include up($md) {
				margin-bottom: 7px;
			}*/
		}

		&__parents {
			width: 40px;
			position: relative;
			top: 5px;
			//left: -6px;

			@include up($sm) {
				width: 97px;
				top: 14px;
				margin: 0 -2px;
			}
		}

		&__speakers {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			max-width: 400px;
			margin: 0 auto;

			@include up($sm) {
				position: relative;
				justify-content: flex-start;

				max-width: 900px;
				margin: 0;
			}
			@include up($md) {
				top: 0;
				height: 342px;
			}

			&-item {
				display: flex;
				flex-direction: column;
				align-items: center;

				@include up($sm) {
					align-items: flex-start;
				}

				.v-image {
					width: 100%;
				}

				&--1 {
					width: 30%;
					max-width: 126px;

					@include up($sm) {
						width: 15%;
					}
					@include up($md) {
						position: absolute;
						right: 180px;
						bottom: -10px;
						width: 114px;

						.home-intro__speakers-name {
							bottom: 5px;
							right: 135px;
							text-align: right;
						}
					}

				}

				&--2 {
					width: 36%;
					max-width: 170px;

					.home-intro__speakers-name {
						text-align: center;
					}

					@include up($sm) {
						width: 19%;
						max-width: 159px;
						margin-left: 5%;

						.home-intro__speakers-name {
							text-align: left;
						}
					}
					@include up($md) {
						position: absolute;
						right: 0;
						bottom: 68px;
						width: 145px;

						.home-intro__speakers-name {
							top: 182px;
							right: 10px;
							text-align: right;
						}
					}
				}

				&--3 {
					width: 30%;
					max-width: 126px;

					@include up($sm) {
						position: relative;
						width: 20%;
						max-width: 170px;
						margin-left: 5%;
					}
					@include up($md) {
						position: absolute;
						right: 180px;
						top: 15px;
						width: 154px;


						.home-intro__speakers-name {
							top: 50px;
							right: 175px;
							text-align: right;
						}
					}
				}
			}

			&-name {
				text-align: left;
				color: $golden;
				//max-width: 47px;
				margin-top: 6px;

				@include up($sm) {
					margin-top: 8px;
				}

				@include up($md) {
					position: absolute;
					margin-top: 0;
				}

				/*&--1 {
					@include up($md) {
						align-self: flex-end;
					}
				}

				&--2 {
					max-width: 100px;
					@include up($sm) {
						max-width: 110px;
					}
				}*/
			}
			&-img {
				max-width: 46px;
			}
			&-more {
				position: absolute;
				left: 81%;
				bottom: 82%;
				background-color: $light-orange;
				// background-image: url('~@/assets/home/plus-2x.png');
				background-repeat: no-repeat;
				background-position: 17px 15px;
				border-radius: 95px;
				transform: rotate(-18.77deg);
				font-size: 24px;
				line-height: 24px;
				width: 204px;
				height: 76px;
				padding: 14px 26px 14px 18px;
				box-sizing: border-box;
				mix-blend-mode: darken; // this makes sticker not visible over girl jpg

				@include up($md) {
					top: -5px;
					left: 88%;
					mix-blend-mode: unset;
				}
			}
		}
	}
</style>