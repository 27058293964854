<template>
	<section class="home-form relative z-index-1" :class="{ final: final }">
		<v-container>
			<div
				class="home-form__bg pa-3 px-sm-6 px-md-9 py-md-7 pt-sm-5 pb-6 pb-sm-10 rounded relative">
				<v-row>
					<v-col v-if="final" cols="12" sm="8" md="8" class="pl-md-12">
						<v-row>
							<v-col cols="12" md="12" class="pt-4 pb-md-5">
								<h2
									class="text-left text-sm-left ml-md-9 pt-sm-5 pt-md-0 size-h2">
									Запишитесь
									<span class="d-none d-sm-inline"><br/></span>
									<span class="d-none d-sm-inline">прямо</span> сейча<span
									@click.ctrl="fillForm"
								>с</span
								>
								</h2>
							</v-col>

							<template>
								<v-col
									cols="12"
									sm="10"
									md="6"
									class="pt-0 pb-1 pt-sm-5 pt-md-5 pb-md-10">
									<div class="d-flex align-start">
										<img
											src="@/assets/home/home-form/fire-2x.png"
											
											class="home-form__img"
										/>
										<div class="size1 pl-1 pl-sm-2 pb-sm-4 pb-md-0">
											Количество мест<br class="d-none d-sm-inline"/> ограничено
										</div>
									</div>
								</v-col>
								<v-col
									cols="12"
									sm="10"
									md="6"
									class="pt-1 pt-md-5 pb-3 pb-md-10 pr-md-0 home-form__text3">
									<div class="d-flex">
										<img
											src="@/assets/home/home-form/prize-2x.png"
											alt="heart"
											class="home-form__img"
										/>
										<div class="size1 pl-1 pl-sm-2">
											Секретные подарки<br/> для первых участников
										</div>
									</div>
								</v-col>
							</template>
						</v-row>
					</v-col>
					<v-col v-else cols="12" sm="8" md="12">
						<v-row>
							<v-col cols="12" class="pt-4">
								<h2 class="text-left text-sm-left">
									Запишитесь
									<span class="d-none d-sm-inline d-md-none"><br/></span>
									<span class="d-none d-sm-inline">прямо</span> сейча<span
									@click.ctrl="fillForm"
								>с</span
								>
								</h2>
							</v-col>
							<template>
								<v-col
									cols="12"
									md="4"
									class="pt-0 pb-1 pt-sm-9 pt-md-5 pb-md-10">
									<div class="d-flex align-start">
										<img
											src="@/assets/home/home-form/chat-2x.png"
											alt="chat"
											class="home-form__img"
										/>
										<div class="size1 pl-1 pl-sm-2">
											Ответим на<br class="d-none d-md-inline"/>
											актуальные вопросы<br class="d-none d-md-inline"/>
											об&nbsp;образовании
										</div>
									</div>
								</v-col>
								<v-col
									cols="12"
									md="4"
									class="pt-1 pt-sm-2 pt-md-5 pb-3 pb-md-10 pl-md-0 home-form__text2">
									<div class="d-flex">
										<img
											src="@/assets/home/home-form/heart-2x.png"
											alt="heart"
											class="home-form__img d-none d-md-block"
										/>
										<img
											src="@/assets/home/home-form/unicorn-2x.png"
											alt="unicorn"
											class="home-form__img d-md-none"
										/>
										<div class="size1 pl-1 pl-sm-2">
											Объясним, как не ссориться<br
											class="d-none d-sm-inline"
										/>
											из-за учёбы и&nbsp;справляться<br
											class="d-none d-sm-inline"
										/>
											со&nbsp;стрессом
										</div>
									</div>
								</v-col>
								<v-col
									cols="12"
									md="4"
									class="d-none d-sm-block pt-1 pt-md-5 pb-3 pb-md-10 px-md-0 home-form__text3">
									<div class="d-flex">
										<img
											src="@/assets/home/home-form/rocket-2x.png"
											alt="rocket"
											class="home-form__img d-none d-md-block"
										/>
										<img
											src="@/assets/home/home-form/heart-2x.png"
											alt="heart"
											class="home-form__img d-md-none"
										/>
										<div class="size1 pl-1 pl-sm-2">
											Обсудим, к какому<br/>будущему готовиться —<br/>
											и как именно
										</div>
									</div>
								</v-col>
							</template>
						</v-row>
					</v-col>

					<!-- FORM -->
					<v-col v-if="final"
						   сols="12"
						   sm="4"
						   md="3"
						   class="pt-sm-11 pl-sm-0 pt-md-3">
						<v-form @submit.prevent="submit" class="relative z-index-1">
							<v-row>
								<v-col
									cols="12"
									sm="12"
									md="12"
									class="py-1 pb-sm-2 pt-sm-6 pt-md-3 pb-md-1 pl-md-7 pr-md-0 mt-md-5 ml-md-5">
									<v-text-field
										label="Имя"
										required
										solo
										hide-details
										flat
										maxlength="128"
										v-model="form.name"
										@focus="onFormFocus"
									/>
								</v-col>
								<v-col
									cols="12"
									sm="12"
									md="12"
									class="py-1 pt-md-1 pl-md-7 pr-md-0 pb-md-1 ml-md-5">
									<v-text-field
										label="Почта"
										required
										solo
										hide-details
										flat
										maxlength="128"
										v-model="form.email"
										@focus="onFormFocus"
									/>
								</v-col>
								<v-col
									cols="12"
									sm="12"
									md="12"
									class="py-1 py-sm-2 pt-md-1 pb-md-1 pl-md-7 pr-md-0 ml-md-5">
									<v-text-field
										label="+7 (999) 999-99-99"
										required
										solo
										hide-details
										flat
										:maxlength="phoneMaxlength"
										v-model="form.phone"
										@focus="onFormFocus"
										@keydown="filterPhoneKey"
										@keyup="fixPhone"
									/>
								</v-col>
								<v-col
									cols="12"
									sm="12"
									md="12"
									class="pt-1 pt-sm-2 pb-3 py-md-1 pl-md-7 pr-md-0 ml-md-5">
									<v-checkbox
										label="Ребёнок учится в Skysmart"
										required
										:ripple="false"
										class="ma-0 pa-0"
										hide-details
										v-model="form.isStudent"
										@focus="onFormFocus">
										<!-- <template v-slot:label>
											<span class="d-flex justify-start align-center">
												<svg v-if="form.isStudent" xmlns="http://www.w3.org/2000/svg"
													 viewBox="0 0 24 24">
													<path
														d="M11 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597zm11-15v20h-20v-20h20zm2-2h-24v24h24v-24z"/>
												</svg>
												<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
													d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"/></svg>
												<span class="ml-2">Ребёнок учится в Skysmart</span>
											</span>
										</template> -->
									</v-checkbox>
								</v-col>
								<v-col
									cols="12"
									sm="12"
									md="12"
									class="pt-0 pt-sm-1 pb-2 py-md-1 pl-md-7 pr-md-0 ml-md-5">
									<v-btn
										block
										:ripple="false"
										elevation="0"
										type="submit"
										class="btn-size"
										@focus="onFormFocus">
										Участвовать бесплатно
									</v-btn>
								</v-col>
								<v-col
									cols="12"
									sm="12"
									md="12"
									class="pt-0 pt-sm-2 pb-3 py-md-1 pl-md-7 pr-md-0 pb-md-5 ml-md-5">
									<p
										class="mb-0 text-center text-sm-left text-md-center size-xs-1 black-grey">
										Оставляя заявку,<br class="d-none d-sm-inline d-md-none"/>
										вы принимаете
										<router-link :to="{ name: 'rules' }" target="_blank"
										>оферту<br class="d-none d-sm-inline d-md-none"/>
											и соглашениe<br class="d-none d-sm-inline d-md-none"/>
											о конфиденциальности
										</router-link>
									</p>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
					<v-col v-else
						   сols="12"
						   sm="4"
						   md="12"
						   class="pt-sm-16 pt-md-3 pl-sm-0 pl-md-3">
						<!--<img
											v-if="!final"
											src="@/assets/home/home-form/SmartFest.svg"
											alt="SmartFest"
											class="home-form__logo d-none d-sm-inline d-md-none mt-5"
									/>-->

						<v-form @submit.prevent="submit" class="relative z-index-1">
							<v-row>
								<v-col
									cols="12"
									sm="12"
									md="3"
									class="py-1 pb-sm-2 pt-sm-6 pt-md-0 pr-md-1 pb-md-1">
									<v-text-field
										label="Имя"
										required
										solo
										hide-details
										flat
										maxlength="128"
										v-model="form.name"
										@focus="onFormFocus"
									/>
								</v-col>
								<v-col
									cols="12"
									sm="12"
									md="3"
									class="py-1 pt-md-0 px-md-1 pb-md-1">
									<v-text-field
										label="Почта"
										required
										solo
										hide-details
										flat
										maxlength="128"
										v-model="form.email"
										@focus="onFormFocus"
									/>
								</v-col>
								<v-col
									cols="12"
									sm="12"
									md="3"
									class="py-1 py-sm-2 pt-md-0 pl-md-1 pb-md-1">
									<v-text-field
										label="+7 (999) 999-99-99"
										required
										solo
										hide-details
										flat
										:maxlength="phoneMaxlength"
										v-model="form.phone"
										@focus="onFormFocus"
										@keydown="filterPhoneKey"
										@keyup="fixPhone"
									/>
								</v-col>
								<v-col
									cols="12"
									sm="12"
									md="3"
									class="pt-1 pt-sm-2 pt-md-3 pb-3"
									order-md="last">
									<v-checkbox
										label="Ребёнок учится в Skysmart"
										required
										:ripple="false"
										class="ma-0 pa-0"
										hide-details
										v-model="form.isStudent"
										@focus="onFormFocus">
										<!-- <template v-slot:label>
											<span class="d-flex justify-start align-center">
												<svg v-if="form.isStudent" xmlns="http://www.w3.org/2000/svg"
													 viewBox="0 0 24 24">
													<path
														d="M11 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597zm11-15v20h-20v-20h20zm2-2h-24v24h24v-24z"/>
												</svg>
												<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
													d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"/></svg>
												<span class="ml-2">Ребёнок учится в Skysmart</span>
											</span>
										</template> -->
									</v-checkbox>
								</v-col>
								<v-col
									cols="12"
									sm="12"
									md="3"
									class="pt-0 pt-sm-1 pt-md-0 pb-2 pb-md-1">
									<v-btn
										block
										:ripple="false"
										elevation="0"
										type="submit"
										@focus="onFormFocus">
										Участвовать бесплатно
									</v-btn>
								</v-col>
								<v-col
									cols="12"
									sm="12"
									md="9"
									class="pt-0 pt-sm-2 pt-md-3 pb-3">
									<p class="mb-0 text-center text-sm-left size-xs black-grey">
										Оставляя заявку, вы принимаете
										<router-link :to="{ name: 'rules' }" target="_blank"
										>оферту и соглашениe о конфиденциальности
										</router-link>
									</p>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
				</v-row>
			</div>
		</v-container>

		<v-dialog v-model="isErrorDialog" width="500">
			<v-card>
				<v-card-title class="justify-center">Поправьте форму</v-card-title>

				<v-card-text class="justify-center text-center size-sm-md">
					{{ error }}
				</v-card-text>

				<v-card-actions class="justify-center">
					<v-btn
						text
						@click="
              error = null;
              isErrorDialog = null;
            "
					>
						Ок
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="isSuccessDialog" width="500">
			<v-card>
				<v-card-title class="justify-center"> Заявка отправлена</v-card-title>

				<v-card-text class="justify-center text-center size-sm-md">
					Успешный запроса на рассылку.
				</v-card-text>

				<v-card-actions class="justify-center">
					<v-btn text @click="isSuccessDialog = null"> Ок</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</section>
</template>

<script>
import HomeDate from "@/components/Home/home-date";
import {event} from "@/utils/gtm";
import Config from "@/config";

export default {
	components: {
		HomeDate,
	},
	props: {
		final: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			form: {
				name: "",
				email: "",
				phone: "",
				isStudent: false,
				url: null,
				isGroupB: false,
			},
			isLoading: false,
			isErrorDialog: false,
			isSuccessDialog: false,
			error: null,
			phoneMaxlength: 32,
		};
	},

	watch: {
		error(val, old) {
			if (val && !old) {
				this.isErrorDialog = true;
				event({
					event: "_leads_form_sent_fail",
					formId: this.formId,
					errorMessage: val,
				});
			} else if (!val && old) this.isErrorDialog = false;
		},
		"form.phone"(val, old) {
			if (val !== old) {
				this.fixPhone();
			}
		},
	},
	computed: {
		formId() {
			return this.final ? "home-bottom" : "home-top";
		},
	},
	methods: {
		onFormFocus() {
			if (!this.$store.state.isHomeFormInteracted) {
				event({
					event: "_leads_form_start_interaction",
					formId: this.formId,
				});
				this.$store.state.isHomeFormInteracted = true;
			}
		},
		submit() {
			console.log("Submitting...");

			event({
				event: "_leads_form_submit",
				formId: this.formId,
			});

			const reName = /^.{2,}$/;
			if (!reName.test(this.form.name)) {
				return (this.error = "Пожалуйста, введите Ваше имя.");
			}

			const reEmail = /^.+?@.+?\..+$/;
			if (!reEmail.test(this.form.email)) {
				return (this.error =
					"Пожалуйста, укажите Ваш адрес электронной почты.");
			}

			const phone = this.form.phone.replace(/\D+/g, "");
			//console.log("Phone: "+phone);
			const rePhone = /^\d{10,24}$/;
			if (!rePhone.test(phone)) {
				return (this.error = "Пожалуйста, укажите Ваш номер телефона.");
			}

			this.form.url = self.location.href;

			this.error = null;
			this.result = null;
			this.isSuccessDialog = false;
			this.isLoading = true;

			//console.log("UTM", this.$route.query);
			//return;

			return this.$store
				.dispatch("post", {
					action: "SubmitController",
					params: this.form,
				})
				.then((res) => {
					console.log("RESULT: ", res);

					// remember entered values
					this.$store.state.homeForm = this.form;
					this.$store.state.homeFormResult = res;

					if (!this.$store.state.isHomeFormSubmitted) {
						event({
							event: "_leads_form_sent_success",
							formId: this.formId,
							'subscribe_to': 'action.grants.is_subscribed'
							//isBump: "true",
						});
						this.$store.state.isHomeFormSubmitted = true;
					}

					// change query
					const query = this.$route.query;
					if ( query['source_type'] === Config.UTM_SOURCE_TYPE_CORPORATE ) {
						// override for B2B
						delete query['utm_promocode'];
						query['promocode'] = Config.CORPORATE_UTM_PROMOCODE;
					}

					//this.isSuccessDialog = true;
					const who = this.form.isStudent ? "student" : "newcomer";
					this.$router.push({
						name: "lesson",
						params: {who},
						query: {...this.$route.query, _submitId: res.SubmitController?.submitId},
					});
				})
				.catch((error) => {
					this.error = res?.error ? res?.error : "Не получилось...";
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		fillForm() {
			this.form.name = "tstprod";
			this.form.email = "test@selfbooking.ru";
			this.form.phone = "+79221996764";
			this.form.isStudent = true;
		},
		filterPhoneKey(e) {
			//console.log(e);
			const a = e.key;

			// ignore arrows, del, backspace
			if (
				e.keyCode === 37 ||
				e.keyCode === 39 ||
				e.keyCode === 8 ||
				e.keyCode === 46 ||
				e.keyCode === 35 ||
				e.keyCode === 36 ||
				e.keyCode === 9 ||
				(e.key === "x" && e.ctrlKey) ||
				(e.key === "c" && e.ctrlKey) ||
				(e.key === "v" && e.ctrlKey)
			)
				return true;

			if (!this.validatePhoneKey(a)) e.preventDefault();
		},
		validatePhoneKey(a) {
			if (!a.match(/[+0-9\(\) -]/)) return false;

			let l = this.form.phone?.length;
			//console.log("a: "+a+", l: "+l);
			if (l === 0) {
				if (!a.match(/[+0-9]/)) return false;
				if (a === "+") this.form.phone = "";
				else if (a !== "8") this.form.phone = "+";
				else if (a === "9") this.form.phone = "+7 (";
			} else if (l === 1) {
				if (this.form.phone === "+" && a === "8") this.form.phone = "";
			}
			return true;
		},
		fixPhone(e) {
			if (
				e &&
				(e.keyCode === 37 ||
					e.keyCode === 39 ||
					e.keyCode === 8 ||
					e.keyCode === 46 ||
					e.keyCode === 35 ||
					e.keyCode === 36)
			)
				return true;

			let s = this.form.phone;
			s = s.replace(/[^0-9]+/g, "");
			//console.log("s=" + s);
			//v = v.substr(1);

			this.phoneMaxlength = 32;
			if (!s.length) return "";
			const f = [];
			for (let i = 0; i < s.length; i++) {
				let l = s[i];
				//if ( i === 0 && l === "+" )
				//console.log(i + "=" + l);

				if (s[0] === "8") {
					// 8 ...
					this.phoneMaxlength = 17;
					if (i === 1) {
						f.push(" ");
						f.push("(");
					} else if (i === 4) {
						f.push(")");
						f.push(" ");
					} else if (i === 7) {
						f.push("-");
					} else if (i === 9) {
						f.push("-");
					}
					f.push(l);
				} else if (s[0] === "7") {
					// +7 ...
					this.phoneMaxlength = 18;
					if (i === 0) {
						f.push("+");
					} else if (i === 1) {
						f.push(" ");
						f.push("(");
					} else if (i === 4) {
						f.push(")");
						f.push(" ");
					} else if (i === 7) {
						f.push("-");
					} else if (i === 9) {
						f.push("-");
					}
					f.push(l);
				} else if (s[0] === "9") {
					// 9 ...
					this.phoneMaxlength = 18;
					if (i === 0) {
						f.push("+");
						f.push("7");
						f.push(" ");
						f.push("(");
					} else if (i === 4) {
						f.push(")");
						f.push(" ");
					} else if (i === 7) {
						f.push("-");
					} else if (i === 9) {
						f.push("-");
					}
					f.push(l);
				} else {
					// [0-6] ...
					this.phoneMaxlength = 24;
					if (i === 0) {
						f.push("+");
					} else if (i === 3) {
						f.push(" ");
					}
					f.push(l);
				}
			}
			const p = f.join("");
			if (p === this.form.phone) return;
			this.form.phone = p;
		},
	},
	mounted() {
		// todo add real visibility test
		if (!this.final && !this.$store.state.isHomeFormVisibilitySubmitted) {
			// need some pause because GTM is delayed
			setTimeout(()=>{
				event({
					event: "_leads_form_in_viewport",
					formId: this.formId,
				});
			},Config.GTM_DELAY+1500);
			this.$store.state.isHomeFormVisibilitySubmitted = true;
		}
	},
};
</script>


<style lang="scss">
.home-form {
	&__bg {
		overflow: hidden;
		background-color: $golden-gradient;
		background-image: url("~@/assets/home/home-form/bg.jpg");
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
	}

	h2 {
		font-size: 30px;
		line-height: 30px;
		letter-spacing: -1.2px;
		@include up($sm) {
			font-size: 64px;
			line-height: 64px;
		}
	}

	&__logo {
		width: 85px;
		@include up($sm) {
			width: 133px;
			//height: 30px;
		}
	}

	&__text {
		&2 {
			@include up($md) {
				position: relative;
				left: -50px;
			}
		}

		&3 {
			@include up($md) {
				position: relative;
				left: -20px;
			}
		}
	}

	&__img {
		width: 22px;
		height: 22px;

		@include up($sm) {
			width: 30px;
			height: 30px;
		}
	}

	a {
		color: $black !important;

		&:hover {
			color: $black !important;
		}
	}

	&.final {
		.home-form__logo {
			@include up($sm) {
				width: 172px;
			}
		}

		.home-form__date {
			font-size: 16px;
			line-height: 18px;
			text-align: right;
			letter-spacing: -0.308697px;

			@include up($sm) {
				font-size: 40px;
				line-height: 44px;
				letter-spacing: -0.6px;
			}
		}
	}

	.size-xs-1 {
		font-size: 12px;
		line-height: 12px;
		letter-spacing: -0.07px;

		@include up($sm) {
			font-size: 16px;
			line-height: 20px;
			letter-spacing: -0.1px;
		}
		@include up($md) {
			font-size: 14px;
			line-height: 16px;
			letter-spacing: -0.3px;
		}
	}

	.size-h2 {
		font-size: 30px;
		line-height: 30px;
		letter-spacing: -0.15px;
		@include up($sm) {
			font-size: 64px;
			line-height: 72px;
			letter-spacing: -0.8px;
		}
		@include up($md) {
			font-size: 64px;
			line-height: 66px;
			letter-spacing: -0.8px;
		}
	}

	.v-form {
		.btn-size {
			@include up($sm) {
				height: 52px !important;
			}
		}

		// .v-input--checkbox {
		// 	.v-input--selection-controls__input {
		// 		width: 0;
		// 	}
		// 	.v-icon {
		// 		display: none;
		// 	}
		// 	svg {
		// 		width: 16px;
		// 		height: 16px;

		// 	}
		// }
	}
}
</style>