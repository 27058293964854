<template>
  <section class="lesson-installment">
    <v-container>
      <div
        class="lesson-installment__board px-4 py-6 px-sm-4 px-md-13 d-sm-flex"
      >
        <div
          class="
            lesson-installment__item lesson-installment__item--1
            d-none d-sm-block
          "
        >
          <div class="lesson-installment__text d-none d-sm-block mb-3">
            <h3>
              Не готовы оплатить<br />
              сразу? В рассрочку<br />
              ещё выгоднее
            </h3>
          </div>
          <div class="lesson-installment__text d-none d-sm-block mb-6">
            Оплачивайте по частям —<br />
            раз в месяц по персональному<br />
            графику
          </div>
          <div class="d-none d-sm-block">
            <v-btn @click="scrollTo('form')" elevation="0">Купить в рассрочку</v-btn>
          </div>
        </div>

        <div class="lesson-installment__item lesson-installment__item--2">
          <div class="d-sm-none mb-2">
            <h3>В рассрочку ещё выгоднее</h3>
          </div>
          <div
            class="
              lesson-installment__text lesson-installment__text--1
              pb-4 pb-sm-3
              mb-2
            "
          >
            Рассрочка на пакеты<br />
            от 32 уроков<br class="d-sm-none" />
            (все занятия Skyeng)
          </div>
          <div
            class="
              lesson-installment__text lesson-installment__text--2
              pb-4 pb-sm-3
              mb-2
            "
          >
            Можно вернуть деньги за<br />
            неиспользованные уроки
          </div>
          <div
            class="
              lesson-installment__text lesson-installment__text--3
              pb-4 pb-sm-3
              mb-2
            "
          >
            Без переплат и долгих<br />
            согласований
          </div>
          <div
            class="
              lesson-installment__text lesson-installment__text--4
              pb-4
              mb-2
              pb-sm-0
              mb-sm-0
            "
          >
            Цена фиксируется на момент<br />
            первой оплаты
          </div>
          <div class="lesson-installment__text d-sm-none mb-8">
            Оплачивайте по частям — раз<br class="d-sm-none" />
            в месяц по персональному<br class="d-sm-none" />
            графику
          </div>
          <div class="d-sm-none">
            <v-btn
              @click="scrollTo('form')"
              block
              elevation="0"
              class="d-sm-none"
              >Купить в рассрочку</v-btn
            >
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import { scrollTo } from "@/utils/utils";

export default {
  methods: {
    scrollTo(el) {
      scrollTo(el);
    },
  },
};
</script>


<style lang="scss">
.lesson-installment {
  h3 {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.15px;
    @include up($sm) {
      font-size: 37px;
      line-height: 44px;
      letter-spacing: -1px;
    }
    @include up($md) {
      font-size: 40px;
      letter-spacing: -0.6px;
    }
  }
  &__board {
    background: $blue3;
    border-radius: 16px;
  }

  &__item {
    &--1 {
      border-right: 2px solid rgba(0, 0, 0, 0.2);
      @include up($sm) {
        padding-right: 14px;
      }
      @include up($md) {
        padding-right: 70px;
      }
    }
    &--2 {
      @include up($sm) {
        padding-left: 14px;
      }
      @include up($md) {
        padding-left: 90px;
      }
    }
  }
  &__text {
    // padding-top: 7px;
    // padding-bottom: 7px;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.004em;
    @include up($sm) {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -1px;
    }
    @include up($md) {
      letter-spacing: -0.6px;
    }

    &--1 {
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    }
    &--2 {
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    }
    &--3 {
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    }
    &--4 {
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
      @include up($sm) {
        border-bottom: none;
      }
    }
  }

  .v-btn {
    border-radius: 10px;
    height: 54px !important;
    // width: 260px;
    color: $white;
    background-color: $blue2 !important;
    text-transform: none;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.05px;
    font-weight: 400;

    @include transition();

    .v-btn__content {
      line-height: inherit;
    }

    @include up($sm) {
      height: 60px !important;
      width: 271px !important;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.0988085px;

      .v-btn__content {
        position: relative;
        top: -1px;
      }
    }

    @include up($md) {
      width: 356px;
    }
  }
}
</style>