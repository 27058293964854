<template>
	<section class="lesson-sticky-header" v-scroll="handleScroll">
		<transition name="sticky-header">
			<div v-if="isVisible" class="lesson-sticky-header__body">
				<v-container class="d-flex align-center">
					<v-row>
						<v-col cols="12" class="d-flex justify-center justify-sm-space-between align-center">
							<div class="d-none d-sm-block">
								<v-img src="@/assets/logo.png" alt="SkySmart" class="lesson-sticky-header__logo"/>
							</div>

							<div class="size1 text-center d-none d-md-block lesson-sticky-header__text">
								{{ introText }}
							</div>

							<v-form @submit.prevent="">
								<v-btn :ripple="false" elevation="0" @click.prevent="scrollTo('form')">
									Записаться
								</v-btn>
							</v-form>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</transition>
	</section>
</template>

<script>
	import {scrollTo} from "@/utils/utils";

	// отступ от верха страницы, когда снова показываем хедер
	const APPEAR_Y = 800;
	const APPEAR_Y_SM = 1000;
	const APPEAR_Y_MD = 800;

	// отступ от низа страницы с учетом высоты окна, когда снова скрываем хедер
	const HIDE_Y = 1000;
	const HIDE_Y_SM = 700;
	const HIDE_Y_MD = 600;

	export default {
		data: () => {
			return {
				isVisible: false,
			}
		},
		computed: {
			isStudent() {
				return this.$store.state.isStudent;
			},
			introText() {
				return this.isStudent ? 'Начните изучать второй предмет с выгодой до 5 уроков' : 'Бесплатный урок в онлайн-школе Skysmart';
			},
			isWindowSM() {
				return this.$store.getters.isWindowSM;
			},
			isWindowMD() {
				return this.$store.getters.isWindowMD;
			},
			appearY() {
				if (this.isWindowMD) return APPEAR_Y_MD;
				if (this.isWindowSM) return APPEAR_Y_SM;
				return APPEAR_Y;
			},
			hideY() {
				if (this.isWindowMD) return HIDE_Y_MD;
				if (this.isWindowSM) return HIDE_Y_SM;
				return HIDE_Y;
			}
		},
		methods: {
			scrollTo(el) {
				scrollTo(el);
			},
			handleScroll() {
				const top = window.scrollY;
				const hideOn = document.body.clientHeight - window.innerHeight - this.hideY;
				//console.log("TOP: ", top);
				if (top >= this.appearY && top <= hideOn) this.isVisible = true;
				else this.isVisible = false;
				/*const rect = this.$refs.intro.getBoundingClientRect();
				//const headerHeight = this.$store.state.headerHeight;
				//if (rect.bottom - this.$store.state.headerHeight > 0) {
				if (rect.bottom > 0) {
					this.$store.state.isHeaderInversed = true;
				} else {
					this.$store.state.isHeaderInversed = false;
				}*/
			}
		}
	};
</script>


<style lang="scss">
	.lesson-sticky-header {
		position: fixed;
		z-index: 10;
		width: 100%;
		height: 65px;
		left: 0;
		bottom: 0;

		@include up($sm) {
			bottom: unset;
			left: 0;
			top: 0;
		}

		&__body {
			height: 65px;
			background-color: $orange4;
			// same bg as at home
			background-image: url('@/assets/home/home-sticky-header/bg.png');
			background-size: cover;
			background-position: bottom center;
		}

		.container {
			height: 65px;
		}

		/*.row {
			height: 65px;
		}*/

		&__logo {
			width: 219px;
			height: 50px;
		}

		&__text {
			margin-top: -4px;
		}

		.v-form {
			.v-btn {
				width: 220px !important;
				height: 38px !important;
				display: block !important;
				//@include up($sm) {
				//height: 38px !important;
				font-size: 14px !important;
				line-height: 17px !important;
				//}
			}
		}
	}

	// выпадание сверху - хорошо для стики-хедера
	.sticky-header {
		&-enter-active, &-leave-active {
			transition: all .3s
		}

		&-enter, &-leave-to {
			opacity: 0;
			transform: translateY(100%);
			@include up($sm) {
				transform: translateY(-100%);
			}
		}
	}


</style>