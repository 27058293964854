<template>
	<home-speakers-card :items="speakers" bg="1" class="--1">
		<h3>Стресс-менеджмент для уставших родителей</h3>
	</home-speakers-card>
</template>

<script>
	import HomeSpeakersCard from "@/components/Home/home-speakers-card";

	export default {
		components: {
			HomeSpeakersCard,
		},
		data: () => {
			return {
				speakers: [
					{
						name: "Ирена Понарошку",
						image: require("@/assets/speakers/original/speaker-ip.png"),
					},
					{
						name: "Людмила Петрановская",
						image: require("@/assets/speakers/original/speaker-lp2.png"),
					},
					{
						name: "Анна Левадная",
						image: require("@/assets/speakers/original/speaker-al.png"),
					},
				],
			};
		},
	};
</script>

<style lang='scss'>
	.home-speakers-card.--1 {
		.home-speakers-card {
			&__names {
				max-width: 300px;

				@include up($sm) {
					max-width: 320px;
					& > div {
						&:nth-child(2) {
							transform: translateX(5%);
						}
						&:nth-child(3) {
							transform: translateX(15%);
						}
					}
				}
				@include up($md) {
					max-width: 320px;
					& > div {
						&:nth-child(2) {
							transform: translateX(2%);
						}
						&:nth-child(3) {
							transform: translateX(5%);
						}
					}
				}
			}

			&__images {
				height: 130px;
				max-width: 350px;

				& > div {
					&:nth-child(1) {
						width: 45%;
					}

					&:nth-child(2) {
						//width: 43%;
						transform: translateX(-45%);
					}

					&:nth-child(3) {
						//width: 43%;
						transform: translateX(2%);
					}
				}

				@include up($sm) {
					height: 150px;
					& > div {
						&:nth-child(2) {
							//width: 43%;
							transform: translateX(-35%);
						}

						&:nth-child(3) {
							//width: 43%;
							transform: translateX(17%);
						}
					}
				}
				@include up($md) {
					& > div {
						&:nth-child(2) {
							//width: 43%;
							transform: translateX(-38%);
						}

						&:nth-child(3) {
							//width: 43%;
							transform: translateX(5%);
						}
					}
				}
			}
		}
	}
</style>