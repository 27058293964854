import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Rules from '../views/Rules.vue'
import Lesson from '../views/Lesson.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/rules',
        name: 'rules',
        component: () => import('../views/Rules.vue'),
    },
    {
        path: '/lesson/:who',
        name: 'lesson',
        component: () => import('../views/Lesson.vue'),
        props: true
    },
    {
        path: '/speakers/:sessionId/:speakerId',
        name: 'speaker',
        component: () => import('../views/Speakers.vue'),
        props: true
    },
    {
        path: '/camp',
        name: 'camp',
        component: () => import('../views/Camp.vue'),
        props: true
    },
]

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
});

router.afterEach((to, from) => {
    setTimeout(() => {
        scrollTo(null, 0, 'instant');

    }, 300);
});

const scrollTo = (hash = null, offset = 0, behavior = 'smooth') => {
    let top = 0;

    if (hash) {
        hash = hash.match(/^\#.+/) ? hash.substring(1) : hash;
        const el = document.getElementById(hash);
        //console.log('Rect:', el.getBoundingClientRect());
        if (el) top = el.getBoundingClientRect().top + window.scrollY + offset;
        else {
            console.warn("Scroll to element not found: " + hash);
            return;
        }
    }

    window.scrollTo({
        behavior,
        left: 0,
        top
    });
};

export default router;
