<template>
	<v-app :class="{fixed:fixed}">
		<v-main>
			<transition name="route" mode="out-in">
				<router-view/>
			</transition>
		</v-main>
		<!-- <main-footer/> -->
	</v-app>
</template>

<script>
    // import MainFooter from "@/components/layouts/main/main-footer";
    import {initGTM, event} from "@/utils/gtm";
	import Config from "@/config";

    export default {
        // components: {
        //     MainFooter,
        // },
        computed: {
            fixed() {
                return this.$route.query?.fixed;
            }
        },
        methods: {
            onResize() {
                //console.log("App onResize");

                this.$store.commit('updateWindowWidth');
                this.$store.commit('updateWindowHeight');

                // на iOS чтобы попадало во весь экран на телефоне 100vh:
                //нужно добавить css переменную vh в main.scss:
                //:root {
                //--vh: 1vh;
                //}
                //после вместо vh использовать var(--vh, 1vh)
                //например всето 100vh = calc(var(--vh, 1vh)*100)
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            },
        },
        mounted() {
            window.addEventListener("resize", this.onResize);
            this.onResize();

            // init GTM and fire first event with AB-test group
			const group = this.$store.state.isGroupB ? "B" : "A";
			initGTM(Config.GTM, Config.GTM_DELAY, {
				'event': 'exp_info',
				'exp_name_and_variant': 'abtest=' + group
			});
		},
        destroyed() {
            window.removeEventListener("resize", this.onResize);
        }
    };
</script>

<style lang='scss'>
	#app {
		min-width: 320px;
		overflow: hidden;
	}
</style>
