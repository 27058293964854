<template>
	<home-speakers-card bg="5" class="home-speakers-card6">
		<h3>Зарегистрируйтесь<br/>на фестиваль<br/>и приводите<br/>с собой детей
		</h3>

		<div class="mt-4 mt-md-6 d-flex align-start">
			<v-img
					src="@/assets/home/home-speakers/rocket-2x.png"
					class="home-speakers__img"
					alt="Wallet"
			/>
			<div class="size-sm-md pl-2 pl-sm-2">
				Свой бизнес в 13 —<br />это не сказки
			</div>
		</div>

		<div class="mt-2  mt-sm-3 d-flex align-start">
			<v-img
					src="@/assets/home/home-speakers/uk-2x.png"
					class="home-speakers__img"
					alt="Wallet"
			/>
			<div class="size-sm-md pl-2 pl-sm-2">
				Как быстро и эффективно запоминать английские слова
			</div>
		</div>

		<div class="mt-2 mt-sm-3 d-flex align-start">
			<v-img
					src="@/assets/home/home-speakers/rock-2x.png"
					class="home-speakers__img"
					alt="Wallet"
			/>
			<div class="size-sm-md pl-2 pl-sm-2">
				Занимательный русский: факты<br />о языке, которые вы не знали
			</div>
		</div>

		<div class="size-sm-md blue2 mt-6 ml-sm-9">Математический<br>квест с Енотом Максом<br>и Небесной
			Академией
		</div>

		<v-img src="@/assets/home/home-speakers/raccoon.png"
			   class="mt-4 home-speakers-card6__raccoon"
			   alt="Енот Макс"/>
	</home-speakers-card>
</template>

<script>
    import HomeSpeakersCard from "@/components/Home/home-speakers-card";

    export default {
        components: {
            HomeSpeakersCard,
        },
        data: () => {
            return {
            }
        }
    };
</script>

<style lang='scss'>
	.home-speakers-card6 {
		&__raccoon {
			margin: 0 auto;
			max-width: 318px;
		}
	}
</style>