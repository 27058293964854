<template>
	<v-form @submit.prevent="submit">
		<v-row>
			<v-col cols="12" class="py-1 pt-sm-3">
				<v-text-field
						label="Имя ребенка"
						dense
						required
						solo
						hide-details
						flat
						maxlength="128"
						v-model="form.childName"
						@click.ctrl="fillForm" @focus="onFormFocus"/>
			</v-col>
			<v-col cols="12" class="py-1">
				<v-text-field
						label="Имя родителя"
						dense
						required
						solo
						hide-details
						flat
						maxlength="128"
						v-model="form.parentName" @focus="onFormFocus"/>
			</v-col>
			<v-col cols="12" class="py-1">
				<v-text-field
						label="Почта"
						dense
						required
						solo
						hide-details
						flat
						maxlength="128"
						v-model="form.email" @focus="onFormFocus"/>
			</v-col>
			<v-col cols="12" class="py-1">
				<v-text-field
						label="Телефон"
						dense
						required
						solo
						hide-details
						flat
						v-model="form.phone"
						:maxlength="phoneMaxlength"
						@keydown="filterPhoneKey"
						@keyup="fixPhone"
						@focus="onFormFocus"/>
			</v-col>
			<v-col class="py-1">
				<v-select
						v-model="form.subject"
						:items="lessons"
						dense
						hide-details
						flat
						label="Выберите предмет"
						:menu-props="{ offsetY: true }"
						solo @focus="onFormFocus">
					<template v-slot:item="{active, item, attrs, on}">
						<v-list-item v-on="on" v-bind="attrs" #default="{ active }"
									 class="lesson-form__menu-item">
							<v-list-item-icon>
								<v-img :src="item.icon" class="lesson-form__menu-icon"/>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>
									{{ item.text }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
				</v-select>
			</v-col>
			<v-col cols="12" class="py-1">
				<v-btn :loading="isLoading" block elevation="0" type="submit" @focus="onFormFocus">Записаться</v-btn>
			</v-col>
			<v-col cols="12" class="pt-0 pb-1">
				<p class="lesson-form__item--4 mb-0 mt-1 mx-sm-6 text-left">
					Нажимая на кнопку, вы принимаете
					<router-link :to="{name:'rules'}" target="_blank">оферту и соглашение о конфиденциальности</router-link>
				</p>
			</v-col>
		</v-row>

		<v-dialog v-model="message.visible" width="460">
			<v-card>
				<v-card-title class="justify-center">{{message.title}}</v-card-title>

				<v-card-text class="justify-center text-center size-sm-md" v-html="message.text"/>

				<v-card-actions class="justify-center">
					<v-btn
							text
							@click="onCloseMessage">
						Ок
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-form>
</template>

<script>
	//import axios from "axios";
	import {skeGetAllUrlParams} from "@/utils/ske-global-helpers";
	import {event} from "@/utils/gtm";
	import Config from "@/config";

	export default {
		props: {
			final: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				lessons: [
					/*
					english_junior_not_native_speaker
					english_klp_not_native_speaker
					chess_kids_f2f
					mathematics_kids
					physics_kids_f2f
					russian_kidssocial_science_kids_f2f
					programming_kids_f2f
					preschool_kids_f2f
					 */
					{
						text: "Английский 10-18 лет",
						value: "english_junior_not_native_speaker",
						icon: require("@/assets/lesson/lesson-form/uk.png"),
					},
					{
						text: "Английский 4-9 лет",
						value: "english_klp_not_native_speaker",
						icon: require("@/assets/lesson/lesson-form/uk.png"),
					},
					{
						text: "Шахматы",
						value: "chess_kids_f2f",
						icon: require("@/assets/lesson/lesson-form/chess.png"),
					},
					{
						text: "Математика",
						value: "mathematics_kids",
						icon: require("@/assets/lesson/lesson-form/math.png"),
					},
					{
						text: "Русский язык",
						value: "russian_kids",
						icon: require("@/assets/lesson/lesson-form/pen.png"),
					},
					{
						text: "Обществознание",
						value: "social_science_kids_f2f",
						icon: require("@/assets/lesson/lesson-form/handshake.png"),
					},
					{
						text: "Основы программирования",
						value: "computer_courses_scratch_kids_f2f",
						icon: require("@/assets/lesson/lesson-form/robot.png"),
					},
					{
						text: "Подготовка к школе",
						value: "preschool_kids_f2f",
						icon: require("@/assets/lesson/lesson-form/plus.png"),
					},
				],
				form: {
					childName: "",
					parentName: "",
					email: "",
					phone: "",
					subject: null,
				},
				isLoading: false,
				message: {
					visible: false,
					title: "",
					text: "",
					code: "",
				},
				phoneMaxlength: 32
			};
		},
		watch: {
			'message.text'(val, old) {
				if (val && !old) {
					console.log("ERROR: ", val);
					event({
						'event': '_orders_form_sent_fail',
						'formId': this.formId,
						'stk': this.form.subject,
						'errorMessage': val,
						'isBump': this.message.code === "bump" ? 'true' : 'false'
					});
				}
				//else if (!val && old) this.isErrorDialog = false;
			},
			'form.phone'(val, old) {
				if (val !== old) {
					this.fixPhone();
				}
			},
		},
		computed: {
			isStudent() {
				return this.$store.state.isStudent;
			},
			formId() {
				return this.final ? "lesson-bottom" : "lesson-top";
			}
		},
		methods: {
			onCloseMessage() {
				this.message.visible = false;
				if (this.link) window.location.href = this.link;
			},
			onFormFocus() {
				if ( !this.$store.state.isLessonFormInteracted ) {
					event({
						'event': '_orders_form_start_interaction',
						'formId': this.formId
					});
					this.$store.state.isLessonFormInteracted = true;
				}
			},
			filterPhoneKey(e) {
				//console.log(e);
				const a = e.key;

				// ignore arrows, del, backspace
				if (e.keyCode === 37
					|| e.keyCode === 39
					|| e.keyCode === 8
					|| e.keyCode === 46
					|| e.keyCode === 35
					|| e.keyCode === 36
					|| e.keyCode === 9
					|| (e.key === "x" && e.ctrlKey )
					|| (e.key === "c" && e.ctrlKey )
					|| (e.key === "v" && e.ctrlKey )
				) return true;

				if (!this.validatePhoneKey(a)) e.preventDefault();
			},
			validatePhoneKey(a) {
				if (!a.match(/[+0-9\(\) -]/)) return false;

				let l = this.form.phone?.length;
				//console.log("a: "+a+", l: "+l);
				if (l === 0) {
					if (!a.match(/[+0-9]/)) return false;
					if (a === "+") this.form.phone = "";
					else if (a !== "8") this.form.phone = "+";
					else if (a === "9") this.form.phone = "+7 (";
				}
				else if (l === 1) {
					if (this.form.phone === "+" && a === "8") this.form.phone = "";
				}
				return true;
			},
			fixPhone(e) {
				if (e && (e.keyCode === 37
					|| e.keyCode === 39
					|| e.keyCode === 8
					|| e.keyCode === 46
					|| e.keyCode === 35
					|| e.keyCode === 36)
				) return true;

				let s = this.form.phone || "";
				s = s.replace(/[^0-9]+/g, '');
				//console.log("s=" + s);
				//v = v.substr(1);

				this.phoneMaxlength = 32;
				if (!s.length) return "";
				const f = [];
				for (let i = 0; i < s.length; i++) {
					let l = s[i];
					//if ( i === 0 && l === "+" )
					//console.log(i + "=" + l);

					if (s[0] === "8") {
						// 8 ...
						this.phoneMaxlength = 17;
						if (i === 1) {
							f.push(" ");
							f.push("(");
						} else if (i === 4) {
							f.push(")");
							f.push(" ");
						} else if (i === 7) {
							f.push("-");
						} else if (i === 9) {
							f.push("-");
						}
						f.push(l);
					} else if (s[0] === "7") {
						// +7 ...
						this.phoneMaxlength = 18;
						if ( i === 0 ) {
							f.push("+");
						} else if (i === 1) {
							f.push(" ");
							f.push("(");
						} else if (i === 4) {
							f.push(")");
							f.push(" ");
						} else if (i === 7) {
							f.push("-");
						} else if (i === 9) {
							f.push("-");
						}
						f.push(l);
					} else if (s[0] === "9") {
						// 9 ...
						this.phoneMaxlength = 18;
						if ( i === 0 ) {
							f.push("+");
							f.push("7");
							f.push(" ");
							f.push("(");
						} else if (i === 4) {
							f.push(")");
							f.push(" ");
						} else if (i === 7) {
							f.push("-");
						} else if (i === 9) {
							f.push("-");
						}
						f.push(l);
					} else {
						// [0-6] ...
						this.phoneMaxlength = 24;
						if ( i === 0 ) {
							f.push("+");
						}
						else if (i === 3) {
							f.push(" ");
						}
						f.push(l);
					}
				}
				const p = f.join("");
				if (p === this.form.phone) return;
				this.form.phone = p;
			},
			submit() {
				console.log("Submitting...");

				event({
					'event': '_orders_form_submit',
					'formId': this.formId,
					'stk': this.form.subject,
				});

				const reChildName = /^.{2,}$/;
				if (!reChildName.test(this.form.childName)) {
					this.message.text = "Пожалуйста, введите имя ребёнка.";
					return this.message.visible = true;
				}

				const reParentName = /^.{2,}$/;
				if (!reParentName.test(this.form.parentName)) {
					this.message.text = "Пожалуйста, введите имя родителя.";
					return this.message.visible = true;
				}

				const reEmail = /^.+?@.+?\..+$/;
				if (!reEmail.test(this.form.email)) {
					this.message.text = "Пожалуйста, укажите Ваш адрес электронной почты.";
					return this.message.visible = true;
				}

				const phone = this.form.phone.replace(/\D+/g, "");
				//console.log("Phone: "+phone);
				const rePhone = /^\d{10,24}$/;
				if (!rePhone.test(phone)) {
					this.message.text = "Пожалуйста, укажите Ваш номер телефона.";
					return this.message.visible = true;
				}

				this.form.url = self.location.href;

				this.message.visible = false;
				this.isLoading = true;

				const params = {
					//type: "Kid",
					//name: this.form.parentName,
					parentName: this.form.parentName,
					parentEmail: this.form.email,
					parentPhone: this.form.phone,
					serviceTypeKey: this.form.subject,
					childName: this.form.childName,
					promocode: Config.LESSON_SUBMIT_PROMOCODE,
					generateLoginLinkTo: "https://c1-business-manager.skyeng.ru/",
					_submitId: this.$route.query?._submitId,
					utms: this.makeOrderUtmParams()
				};

				/*console.log("DATA: ", data);
				return;*/

				return this.$store
					.dispatch("post", {
						action: "LessonController",
						params,
					})
					.then((res) => {
						if (res.LessonController?.link) {

							if (!this.$store.state.isLessonFormSubmitted) {
								event({
									'event': '_orders_form_sent_success',
									'formId': this.formId,
									'stk': this.form.subject,
								});
								this.$store.state.isLessonFormSubmitted = true;
							}

							window.location.href = res.LessonController?.link;
						} else {
							this.message.title = "Спасибо!";//res.LessonController?.title || "Спасибо!";
							this.message.text = res.LessonController?.message || "Мы скоро свяжемся с Вами";
							//this.link = res.LessonController?.link;
							this.message.visible = true;
						}
					})
					.catch((res) => {
						this.message.title = "Пожалуйста, проверьте";//res?.title ? res?.title : "Что-то пошло не так";
						this.message.text = res?.error ? res?.error : "Не получилось...";
						this.message.code = res?.code;
						this.message.visible = true;
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
			makeOrderUtmParams() {
				const utms = skeGetAllUrlParams(window.location.href);
				utms["product"] = Config.LESSON_SUBMIT_PRODUCT;
				//utms["utm_product"] = Config.LESSON_SUBMIT_PRODUCT;
				delete utms["utm_product"];
				//delete utms["product"];
				delete utms["promocode"];
				delete utms["utm_promocode"];
				return utms;
			},
			fillForm() {
				this.form.parentName = "tstprod";
				this.form.childName = "tstprod";
				this.form.email = "test@selfbooking.ru";
				this.form.phone = "+79221996764";
				this.form.subject = this.lessons[3]['value'];
			},
		},
		mounted() {
			this.form.parentName = this.$store.state.homeForm?.name;
			this.form.email = this.$store.state.homeForm?.email;
			this.form.phone = this.$store.state.homeForm?.phone;

			// todo add real visibility test
			if (!this.final && !this.$store.state.isLessonFormVisibilitySubmitted) {
				event({
					'event': '_orders_form_in_viewport',
					'formId': this.formId

				});
				this.$store.state.isLessonFormVisibilitySubmitted = true;
			}
		}
	};
</script>

<style lang="scss">
	.lesson-form {
		&__menu {
			&-item {
				.v-list-item__icon {
					margin-right: 16px !important;
				}

				.v-list-item__title {
					font-size: 18px !important;
				}
			}


			&-icon {
				width: 32px;
				height: 32px;
				position: relative;
				top: -4px;
			}
		}
	}
</style>