window.skeAjaxURL = 'https://magazine.skyeng.ru/wp-admin/admin-ajax.php';

/**
 * @param orderData             CRM order data
 * sample: orderData = {
 *     *type - 'Adult' or 'Kid'
 *     *name - user name (parent name for 'Kid' order type)
 *     *?email - user email, can be empty if phone is specified (parent email for 'Kid' order type)
 *     *?phone - user phone, can be empty if email is specified (parent phone for 'Kid' order type)
 *     ?promocode - Promocode to use, optional
 *     ?orderPage - order page for CRM, optional
 *     ?childName - child name, for 'Kid' order type only
 *     ?childAge - child age, for 'Kid' order type only
 *     ?subject - 'english' or 'mathematics', for 'Kid' order type only
 *     ?purpose - Added to the order comment in CRM, for 'Kid' order type only
 *     ?serviceTypeKey - Order Service Type Key, for possible values see https://confluence.skyeng.ru/pages/viewpage.action?pageId=47123055
 *     ?orderUtmParams {} - object with custom utm parameters to attach to the order request
 *     ?withCredentials - auto login user or not
 *     ?generateLoginLinkTo - to use LoginLink
 * }
 * @param leadData              Magazine Leads DB lead data
 * sample: leadData = {
 *     *leadType - type of the order lead
 *     *leadSubType - subtype of the order lead
 *     ?leadExtraData - object with additional data to store in lead DB database
 *     ?gtmEventData: {
 *         success: {} - object to push to GTM dataLayer on order success
 *         error: {} - object to push to GTM dataLayer on order error
 *     }
 * }
 * @param customerData          Customer event data
 * sample customerData = {
 *     action - action to send to customer
 *     source - source of the request e.g. 'magazine'
 *     is_single_opt_in - true or false
 *     event_attributes = {} - customer event attributes
 *     customer_attributes = {} - customer attributes
 * }
 * @param redirectOnSuccess     Auto redirect to https://c1-business-manager.skyeng.ru/ on order creation success
 * @param orderSuccessCallback  Order Success callback
 * @param orderErrorCallback    Order Error callback
 * @param orderCompleteCallback Order Complete callback
 */
export function skeCreateOrderV2( orderData, leadData, customerData, redirectOnSuccess, orderSuccessCallback, orderErrorCallback, orderCompleteCallback ) {
	var crmEndpointURL = '';
	var crmOrderData = {};
	var crmOrderStatus = 'no-status';

	if ( ! leadData.leadExtraData ) {
		leadData.leadExtraData = {}
	}

	if ( ! leadData.gtmEventData ) {
		leadData.gtmEventData = {
			success: false,
			error: false,
		}
	}

	switch ( orderData.type ) {
		case 'Adult':
			crmEndpointURL = 'https://skygate.skyeng.ru/api/v1/proxy-adult/create';
			crmOrderData = {
				name: orderData.name,
				email: orderData.email,
				phone: orderData.phone
			};
			break;
		case 'Kid':
			crmEndpointURL = 'https://skygate.skyeng.ru/api/v1/proxy-kid/create';
			crmOrderData = {
				parentName: orderData.name,
				parentEmail: orderData.email,
				parentPhone: orderData.phone,
				subject: orderData.subject,
				childName: orderData.childName
			};
			if ( orderData.childAge ) {
				crmOrderData.childAge = orderData.childAge;
				leadData.leadExtraData.child_age = orderData.childAge;
			}
			if ( orderData.purpose ) {
				crmOrderData.purpose = orderData.purpose;
				leadData.leadExtraData.purpose = orderData.purpose;
			}
			leadData.leadExtraData.child_name = orderData.childName;
			break;
		default:
			if ( leadData.gtmEventData.error ) {
				skeGtmDataLayerPush( leadData.gtmEventData.error );
			}
			orderErrorCallback( {}, 'Произошла ошибка: Неизвестный тип заявки', 'Неизвестный тип заявки' );
	}
	if ( orderData.promocode ) {
		crmOrderData.promocode = orderData.promocode;
		crmOrderData.promoCode = orderData.promocode;
	}
	if ( orderData.orderPage ) {
		crmOrderData.orderPage = orderData.orderPage;
	}
	if ( orderData.serviceTypeKey ) {
		crmOrderData.serviceTypeKey = orderData.serviceTypeKey;
		leadData.leadExtraData.serviceTypeKey = orderData.serviceTypeKey;
	}
	if ( orderData.generateLoginLinkTo ) {
		crmOrderData.generateLoginLinkTo = orderData.generateLoginLinkTo;
	}

	leadData.leadExtraData.order_type = orderData.type;
	if ( orderData.orderUtmParams ) {
		crmEndpointURL += '?' + skeLinkParamsObjectToString( orderData.orderUtmParams );
		leadData.leadExtraData.url_params = orderData.orderUtmParams;
	}

	$.ajax( {
		type: 'POST',
		dataType: 'json',
		url: crmEndpointURL,
		xhrFields: {
			withCredentials: orderData.withCredentials === undefined ? true : orderData.withCredentials
		},
		data: crmOrderData,
		error: function ( crmOrderErrorResponse ) {
			console.warn( 'Order createV2 error', crmOrderErrorResponse );
			crmOrderStatus = 'error: 1';
		},
		success: function ( crmOrderSuccessResponse ) {
			if ( crmOrderSuccessResponse && crmOrderSuccessResponse.message === 'OK' ) {
				console.log( 'Order createV2 success', crmOrderSuccessResponse );
				crmOrderStatus = 'ok';
			} else {
				console.warn( 'Order createV2 error', crmOrderSuccessResponse );
				crmOrderStatus = 'error: 2';
			}
		},
		complete: function ( crmOrderCompleteResponse ) {
			var crmResponseData = false;
			if ( crmOrderCompleteResponse.responseText ) {
				crmResponseData = JSON.parse( crmOrderCompleteResponse.responseText )
			}
			if ( crmOrderStatus !== 'ok' ) {
				if ( crmResponseData && crmResponseData.errors && crmResponseData.errors._.length && crmResponseData.errors._[0] ) {
					crmOrderStatus = crmResponseData.errors._[0];
				} else if ( crmResponseData.error ) {
					crmOrderStatus = crmResponseData.error;
				}
			}
			leadData.leadExtraData.order_status = crmOrderStatus;
			skeLeadProcess(
				orderData.email,
				orderData.name,
				orderData.phone,
				customerData,
				leadData.leadType,
				leadData.leadSubType,
				leadData.leadExtraData,
				function( leadProcessResponse, leadProcessStatus, leadProcessResponseData ) {
					console.log( 'leadProcessResponse', leadProcessResponse );
					console.log( 'leadProcessStatus', leadProcessStatus );
					console.log( 'leadProcessResponseData', leadProcessResponseData );
					if ( crmOrderStatus === 'ok' ) {
						var userLogIn = ( crmResponseData.userLogIn && crmResponseData.userLogIn === true );
						var crmSuccessMsg = 'Ваша заявка успешно отправлена<br>В ближайшее время с вами свяжется наш менеджер';
						if ( redirectOnSuccess && userLogIn ) {
							crmSuccessMsg = 'Ваша заявка успешно отправлена!'
							orderSuccessCallback( crmOrderCompleteResponse, userLogIn, crmSuccessMsg );
							var redirectLink = false;
							if ( orderData.generateLoginLinkTo && crmResponseData.loginLink ) {
								redirectLink = crmResponseData.loginLink;
							}
							if ( leadData.gtmEventData.success ) {
								leadData.gtmEventData.success.eventCallback = skeRedirectToOnboarding(redirectLink);
								skeGtmDataLayerPush( leadData.gtmEventData.success );
							} else {
								skeRedirectToOnboarding(redirectLink);
							}
						} else {
							if ( leadData.gtmEventData.success ) {
								skeGtmDataLayerPush( leadData.gtmEventData.success );
							}
							orderSuccessCallback( crmOrderCompleteResponse, userLogIn, crmSuccessMsg );
						}
					} else {
						var crmErrMsg = 'Произошла ошибка, попробуйте еще раз';
						if ( crmOrderStatus !== 'error: 1' && crmOrderStatus !== 'error: 2' ) {
							crmErrMsg = 'Произошла ошибка, попробуйте еще раз: ' + crmOrderStatus;
						}
						if ( leadData.gtmEventData.error ) {
							if ( leadData.gtmEventData.error.eventLabel ) {
								leadData.gtmEventData.error.eventLabel = leadData.gtmEventData.error.eventLabel.replace('{{error_text}}', crmOrderStatus);
							}
							skeGtmDataLayerPush( leadData.gtmEventData.error );
						}
						orderErrorCallback( crmOrderCompleteResponse, crmErrMsg, crmOrderStatus )
					}
					orderCompleteCallback( crmOrderCompleteResponse, crmOrderStatus );
				}
			);
		}
	} );
}

export function skeLeadProcess( email, name, phone, customerData, leadType, leadSubType, leadExtraData, leadProcessCompleteCallback ) {
	var leadProcessStatus = 'no-status';
	var leadProcessResponseData = 'no-data';

	if ( ! leadExtraData.hasOwnProperty( 'url_params' ) ) {
		leadExtraData.url_params = skeGetAllUrlParams( window.location.href ) || {};
	}
	$.ajax( {
		type: 'POST',
		dataType: 'json',
		url: skeAjaxURL,
		xhrFields: {
			withCredentials: true
		},
		data: {
			action: 'ske_lead_process',
			userEmail: email,
			userName: name,
			userPhone: phone,
			customerData: JSON.stringify( customerData ),
			leadType: leadType,
			leadSubType: leadSubType,
			leadExtraData: JSON.stringify( leadExtraData ),
			urlSource: window.location.href,
			urlReferer: skeReferer
		},
		error: function ( leadProcessErrorResponse ) {
			console.warn( 'Lead process error', leadProcessErrorResponse );
			leadProcessStatus = 'error: 1';
			if ( leadProcessErrorResponse.data ) {
				leadProcessResponseData = leadProcessErrorResponse.data;
			}
		},
		success: function ( leadProcessSuccessResponse ) {
			if ( leadProcessSuccessResponse && leadProcessSuccessResponse.success ) {
				console.log( 'Lead process success', leadProcessSuccessResponse );
				leadProcessStatus = 'ok';
			} else {
				console.warn( 'Lead process error', leadProcessSuccessResponse );
				leadProcessStatus = 'error: 2';
			}
			if ( leadProcessSuccessResponse.data ) {
				leadProcessResponseData = leadProcessSuccessResponse.data;
			}
		},
		complete: function ( leadProcessResponse ) {
			leadProcessCompleteCallback( leadProcessResponse, leadProcessStatus, leadProcessResponseData );
		}
	} );
}

export function skeRedirectToOnboarding(redirectLink) {
	if (!redirectLink) {
		redirectLink = 'https://c1-business-manager.skyeng.ru/';
	}
	setTimeout( function() {
		window.location.href = redirectLink;
	}, 0 );
}

export function skeLinkParamsObjectToString(object) {
	var array = [];
	for (var i in object) {
		array.push(i + "=" + object[i]);
	}
	return array.join('&');
}

export function skeGetAllUrlParams(url) {
	// get query string from url (optional) or window
	var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

	// we'll store the parameters here
	var obj = {};

	// if query string exists
	if (queryString) {
		// stuff after # is not part of query string, so get rid of it
		queryString = queryString.split('#')[0];

		// split our query string into its component parts
		var arr = queryString.split('&amp;');
		if ( arr.length < 2 ) {
			arr = queryString.split('&');
		}

		for (var i = 0; i < arr.length; i++) {
			// separate the keys and the values
			var a = arr[i].split("=");

			// in case params look like: list[]=thing1&list[]=thing2
			var paramNum = undefined;
			var paramName = a[0].replace(/\[\d*\]/, function(v) {
				paramNum = v.slice(1, -1);
				return "";
			});

			// set parameter value (use 'true' if empty)
			var paramValue = typeof a[1] === 'undefined' ? 'true' : a[1];

			// (optional) keep case consistent
			paramName = paramName.toLowerCase();
			paramValue = paramValue.toLowerCase();

			// if parameter name already exists
			if (obj[paramName]) {
				// convert value to array (if still string)
				if (typeof obj[paramName] === "string") {
					obj[paramName] = [obj[paramName]];
				}
				// if no array index number specified...
				if (typeof paramNum === "undefined") {
					// put the value on the end of the array
					obj[paramName].push(paramValue);
				}
				// if array index number specified...
				else {
					// put the value at that index number
					obj[paramName][paramNum] = paramValue;
				}
			}
			// if param name doesn't exist yet, set it
			else {
				obj[paramName] = paramValue;
			}
		}
	}

	return obj;
}

export function skeParseURL( url ) {
	var match = url.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);

	if ( match ) {
		return {
			href: url,
			protocol: match[1],
			host: match[2],
			hostname: match[3],
			port: match[4],
			pathname: match[5],
			search: match[6],
			hash: match[7]
		};
	}

	return false
}

export function skeGtmDataLayerPush( data ) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push( data );
}
