<template>
  <section class="lesson-form">
    <v-container>
      <div class="lesson-form__board rounded px-4 py-5 py-sm-6 py-md-9 px-sm-9">
        <div class="lesson-form__item--1 relative z-index-1">
          <h1 v-if="isStudent">
            Нравятся наши уроки? Начните изучать<br
              class="d-none d-md-inline"
            />
            второй предмет с выгодой
          </h1>
          <h1 v-else>
            Запишите ребёнка <br />
            на бесплатный урок<br class="d-none d-md-inline" />
            в&nbsp;онлайн-школе Skysmart
          </h1>
        </div>
        <div class="lesson-form__item--2 mt-7 mb-6 ma-sm-0 relative z-index-1">
          <template v-if="isStudent">
            <div class="size-md-md d-flex">
              <span
                >+2 урока по новым<br />
                предметам</span
              >
            </div>
          </template>
          <template v-else>
            <div class="pl-sm-6">
              +3 урока в подарок<br class="d-md-none" />
              по промокоду <br class="d-none d-md-inline" />
              <span class="lesson-form__promocode">
                &emsp;&emsp;&emsp;
                <img
                  v-if="isB2B"
                  src="@/assets/lesson/lesson-form/promocode2-2x.png"
                  alt="promocode"
                  class="lesson-form__promocode-img--2" />
                <img
                  v-else
                  src="@/assets/lesson/lesson-form/promocode-2x.png"
                  alt="promocode"
                  class="lesson-form__promocode-img"
              /></span>
              <br class="d-none d-sm-inline d-md-none" />
              при<br class="d-sm-none d-md-none" />
              оплате пакета<br class="d-none d-sm-inline d-md-none" />
              от 8 уроков
            </div>
          </template>
        </div>
        <div class="lesson-form__item--3 relative z-index-1">
          <lesson-form-self />
        </div>

        <v-img
          src="@/assets/lesson/lesson-form/girl.png"
          alt="Ребёнок"
          contain
          class="lesson-form__girl"
        />

        <div class="lesson-form__bg" />
      </div>
    </v-container>
  </section>
</template>

<script>
import LessonFormSelf from "./lesson-form-self";
import Config from "@/config";

export default {
  components: {
    LessonFormSelf,
  },
  data() {
    return {};
  },
  computed: {
    isStudent() {
      return this.$store.state.isStudent;
    },
    isB2B() {
      return (
        this.$route.query["source_type"] === Config.UTM_SOURCE_TYPE_CORPORATE ||
        this.$route.query["promocode"] === Config.CORPORATE_UTM_PROMOCODE
      );
    },
  },
  mounted() {
    console.log("Lesson promocode: " + (this.isB2B ? "B2BFEST" : "FEST"));
  },
};
</script>

<style lang="scss">
.lesson-form {
  h1 {
    font-size: 34px;
    line-height: 36px;

    @include up($sm) {
      font-size: 60px;
      line-height: 62px;
    }
  }

  &__board {
    overflow: hidden;
    position: relative;
    background: linear-gradient(180deg, #ffc157 0%, #ffffff 113.03%);
    //background-image: url("~@/assets/lesson/lesson-form/bg.png");

    @include up($sm) {
      //background-image: url("~@/assets/lesson/lesson-form/bg-sm.jpg");
      //background-image: url("~@/assets/lesson/lesson-form/bg.jpg");

      display: grid;
      grid-gap: 50px 15px;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "title title" "form text-photo" "form text-photo";
    }
    @include up($md) {
      background-image: url("~@/assets/lesson/lesson-form/bg.jpg");
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: cover;
      grid-template-columns: 2fr 1fr;
      grid-template-areas: "title form" "title form" "text-photo form";
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1460px;
    height: 335px;
    background-image: url("~@/assets/lesson/lesson-form/trees.png");
    background-position: left bottom;
    background-repeat: repeat-x;
    opacity: 0.4;

    @include up($sm) {
      opacity: 1;
    }
  }

  &__promocode {
    position: relative;
    right: 58px;

    &-img {
      width: 53px;
      height: 28px;
      position: absolute;
      top: 0;
      left: 57px;
      // transform: rotate(-7.77deg);
      @include up($sm) {
        top: -3px;
        left: 55px;
      }

      @include up($sm) {
        width: 86px;
        height: 45px;
      }

      @include up($md) {
        left: 43px;
        top: 0;
      }
      &--2 {
        width: 53px;
        height: 21px;
        position: absolute;
        top: 1px;
        left: 57px;
        transform: rotate(-7.77deg);
        @include up($sm) {
          top: -1px;
          left: 59px;
          width: 86px;
          height: 35px;
        }

        @include up($md) {
          left: 43px;
          top: 0;
        }
      }
    }
  }

  &__item {
    &--1 {
      @include up($sm) {
        grid-area: title;
      }
    }

    &--2 {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -1.2px;
      @include up($sm) {
        grid-area: text-photo;
        font-size: 24px;
        line-height: 32px;
      }

      .size-md-md {
        @include up($sm) {
          letter-spacing: -1.5px;
        }
      }
    }

    &--3 {
      @include up($sm) {
        grid-area: form;
      }
    }

    &--4 {
      font-size: 12px;
      line-height: 18px;

      @include up($sm) {
        line-height: 16px;
      }
    }
  }

  &__girl {
    display: none;

    @include up($sm) {
      display: block;
      position: absolute;
      z-index: 2;
      right: 7%;
      bottom: 0;
      height: 45%;
      max-height: 365px;
    }

    @include up($md) {
      right: unset;
      left: 43%;
      height: 66%;
    }
  }

  &__menu {
    &-item {
      .v-list-item__icon {
        margin-right: 16px !important;
      }

      .v-list-item__title {
        font-size: 18px !important;
      }
    }

    &-icon {
      width: 32px;
      height: 32px;
      position: relative;
      top: -4px;
    }
  }
}
</style>