<template>
	<div class="home" :class="{ '--a': !isB, '--b': isB }">
		<div class="relative z-index-1">
			<home-sticky-header/>

			<template v-if="isB">
				<home-intro-b/>
				<home-form-b id="form"/>
			</template>
			<template v-else>
				<home-intro/>
				<home-form id="form"/>
			</template>

			<home-speakers/>
			<home-roadmap/>
			<home-comments/>
			<home-about/>

			<template v-if="isB">
				<home-form-b final/>
			</template>
			<template v-else>
				<home-form final/>
			</template>

			<main-footer/>
		</div>
		<template v-if="isB">
			<div class="home__gradient-header"></div>
			<div class="home__gradient-footer"></div>
		</template>
	</div>
</template>

<script>
	import HomeStickyHeader from "@/components/Home/home-sticky-header";
	import HomeIntro from "@/components/Home/home-intro";
	import HomeIntroB from "@/components/Home/home-intro-b";
	import HomeRoadmap from "@/components/Home/home-roadmap";
	import HomeSpeakers from "@/components/Home/home-speakers";
	import HomeComments from "@/components/Home/home-comments";
	import HomeAbout from "@/components/Home/home-about";
	import HomeForm from "@/components/Home/home-form";
	import HomeFormB from "@/components/Home/home-form-b";
	import MainFooter from "@/components/layouts/main/main-footer";
	import Config from "@/config";

	export default {
		components: {
			HomeStickyHeader,
			HomeIntro,
			HomeIntroB,
			HomeRoadmap,
			HomeSpeakers,
			HomeComments,
			HomeAbout,
			HomeForm,
			HomeFormB,
			MainFooter,
		},
		data() {
			return {
				isB: false,
			};
		},
		created() {
			let isB = window.localStorage.getItem(Config.STORAGE_GROUP_KEY);
			if (this.$route.query['reset-group']) {
				isB = undefined;
				console.log("GROUP RESET");
			}

			if (isB === undefined || isB === null) {
				const r = Math.random();
				if (r >= 0.5) this.isB = this.$store.state.isGroupB = true;
				window.localStorage.setItem(Config.STORAGE_GROUP_KEY, this.isB ? "true" : "false");
				console.log("GROUP SET: " + (this.isB ? "B" : "A"));
			} else {
				this.isB = this.$store.state.isGroupB = isB === "true";
				console.log("GROUP RESTORED: " + (this.isB ? "B" : "A"));
			}
		},
	};
</script>


<style lang='scss'>
	.home {
		// home-a
		&.--a {
			background: linear-gradient(180deg, #ffffff 90.5%, #ffb53d 108.15%);
		}

		&__gradient-header {
			position: absolute;
			top: 0;
			height: 900px;
			width: 100%;
			background: linear-gradient(180deg, #FF9E00 0%, rgba(255, 158, 0, 0) 100%);
			@include up($md) {
				height: 903px;
			}
		}

		&__gradient-footer {
			position: absolute;
			bottom: 0;
			height: 890px;
			width: 100%;
			background: linear-gradient(0deg, #ff9e00 0%, rgba(255, 158, 0, 0) 100%);
			@include up($md) {
				height: 690px;
				background: linear-gradient(
								0deg,
								rgba(255, 180, 58, 0.96) 0%,
								rgba(255, 158, 0, 0) 100%
				);
			}
		}

		// home-b
		// &.--b {
		//   background: linear-gradient(
		//     180deg,
		//     #ff9e02 0%,
		//     #ffffff 12.22%,
		//     #fcfcfc 90.07%,
		//     #ff9e02 98.3%
		//   );
		//   @include up($sm) {
		//     background: linear-gradient(
		//       180deg,
		//       #ff9e02 7%,
		//       #ffffff 19.22%,
		//       #fcfcfc 90.07%,
		//       #ff9e02 98.3%
		//     );
		//   }
		// }
	}

	.home-intro {
		margin-bottom: 2px;

		@include up($sm) {
			margin-top: 10px;
			margin-bottom: 32px;
		}

		@include up($md) {
			margin-top: 25px;
			margin-bottom: 32px;
		}
	}

	.home-form {
		a {
			color: $black-grey !important;
		}

		margin-bottom: 10px;
		@include up($sm) {
			margin-bottom: 60px;
		}
		@include up($md) {
			margin-bottom: 80px;
		}

		&.final {
			margin-bottom: 10px;
			@include up($sm) {
				margin-bottom: 50px;
			}
			@include up($md) {
				margin-bottom: 60px;
			}
		}
	}

	.home-intro-b {
		@include up($sm) {
			margin-bottom: 20px;
		}
		@include up($md) {
			margin-bottom: 0;
		}
	}

	.home-form-b {
		margin-bottom: 20px;
		@include up($sm) {
			margin-bottom: 100px;
		}
		@include up($md) {
			margin-bottom: 80px;
		}

		&.final {
			margin-bottom: 50px;
			@include up($sm) {
				margin-bottom: 70px;
			}
			@include up($md) {
				margin-bottom: 100px;
			}
		}
	}

	.home-speakers {
		margin-bottom: 25px;
		@include up($sm) {
			margin-bottom: 92px;
		}
		@include up($md) {
			margin-bottom: 100px;
		}
	}

	.home-roadmap {
		margin-bottom: 0;
		@include up($sm) {
			margin-bottom: 30px;
		}
		@include up($md) {
			margin-bottom: 96px;
		}
	}

	.home-comments {
		margin-bottom: 40px;
		@include up($sm) {
			margin-bottom: 50px;
		}
		@include up($md) {
			margin-bottom: 140px;
		}
	}

	.home-about {
		margin-bottom: 20px;
		@include up($sm) {
			margin-bottom: 70px;
		}
		@include up($md) {
			margin-bottom: 140px;
		}
	}
</style>