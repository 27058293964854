<template>
  <div class="rules">
    <rules-promotion />
    <main-footer />
  </div>
</template>

<script>
import RulesPromotion from "@/components/Rules/rules-promotion";
import MainFooter from "@/components/layouts/main/main-footer";

export default {
  components: {
    RulesPromotion,
    MainFooter
  }
}
</script>