<template>
  <home-speakers-card :items="speakers" bg="4" class="--3">
    <h3>Лайфхаки:<br />как выгодно инвестировать<br />в учёбу ребёнка</h3>
    <!-- <div class="size-sm-md light-grey2 mt-4">
			Сессия от Тинькофф — эксперта в инвестициях и финансах <br/>
			для детей и родителей
		</div> -->

    <!-- <div class="mt-2 mt-sm-4 mt-md-4 d-flex align-start">
			<v-img
					src="@/assets/home/home-speakers/wallet2-2x.png"
					class="home-speakers__img"
					alt="Wallet"
			/>
			<div class="size-sm-md pl-2 pl-sm-2">
				Учим копить на обучение вместе с экспертом Тинькофф
			</div>
		</div>

		<div class="mt-2 mt-sm-3 mb-md-8 d-flex align-start">
			<v-img
					src="@/assets/home/home-speakers/wallet-2x.png"
					class="home-speakers__img"
					alt="Wallet"
			/>
			<div class="size-sm-md pl-2 pl-sm-2">
				Учим детей финграмотности<br/>с картой и приложением «Тинькоф Джуниор»
			</div>
		</div> -->
  </home-speakers-card>
</template>

<script>
import HomeSpeakersCard from "@/components/Home/home-speakers-card";

export default {
  components: {
    HomeSpeakersCard,
  },
  computed: {
    isWindowSM() {
      return this.$store.getters.isWindowSM;
    },
    isWindowMD() {
      return this.$store.getters.isWindowMD;
    },
  },
  data: () => {
    return {
      speakers: [
        {
          name: "Ксения Падерина",
          image: require("@/assets/speakers/original/speaker-kp.png"),
        },
        {
          name: "Андрей Иванов",
          image: require("@/assets/speakers/original/speaker-ai-2x.png"),
        },
        {
          name: "Юлия Черепанова",
          image: require("@/assets/speakers/original/speaker-chyu.png"),
        },
      ],
    };
  },
};
</script>

<style lang='scss'>
.home-speakers-card.--3 {
  .home-speakers-card {
    &__names {
      max-width: 300px;
      @include up($sm) {
        max-width: 320px;
        & > div {
          &:nth-child(2) {
            transform: translateX(5%);
          }
          &:nth-child(3) {
            transform: translateX(15%);
          }
        }
      }
    }

    &__images {
      height: 130px;
      max-width: 360px;

      & > div {
        &:nth-child(1) {
          // transform: translateX(41%);
          // left: -10%;
          z-index: 0;
        }

        &:nth-child(2) {
          transform: translateX(-53%);
        }

        &:nth-child(3) {
          transform: translateX(-15%);
          z-index: 1;
          bottom: -8px;
        }
      }

      @include up($sm) {
        height: 150px;

        & > div {
          &:nth-child(1) {
            transform: translateX(4%);
          }

          &:nth-child(2) {
            transform: translateX(-52%);
            height: 148px;
          }

          &:nth-child(3) {
            transform: translateX(-9%);
            bottom: -4px;
            height: 148px;
          }
        }
      }
    }
  }
}
</style>