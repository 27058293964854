export const event = (params) => {
	//console.log("EVENT try: ", params);
	if (typeof window.dataLayer !== "undefined") {
		window.dataLayer.push(params);
		console.log("EVENT: ", params);
	}
};

export const initGTM = (gtm, delay=1500, initialEvent=null) => {
	document.addEventListener("DOMContentLoaded", function() {
		var queryString = window.location.search;
		var urlParams = new URLSearchParams(queryString);
		if ( urlParams.get('noGTM') ) {
			console.log("GTM skipped due to URL query param");
			return;
		}
		if ( window.location.host.match(/localhost/) ) {
			console.log("GTM skipped due to localhost");
			return;
		}
		if ( window.location.host.match(/playnext/) ) {
			console.log("GTM skipped due to beta");
			return;
		}
		if ( !gtm ) {
			console.log("GTM skipped due undefined GTM ID");
			return;
		}

		setTimeout(function(){
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer',gtm);

			console.log("GTM inited: "+gtm);

			if ( initialEvent ) event(initialEvent);

		},delay);
	});

};