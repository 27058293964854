<template>
  <section class="home-intro-b">
    <v-container class="pb-0 relative">
      <v-row>
        <v-col cols="12" md="7" class="home-intro-b__item pb-0 pb-sm-2 pt-4 pt-sm-5 pt-md-8 relative z-index-1">
          <v-img
            src="@/assets/logo-2x.png"
            alt="SkySmart"
            class="home-intro-b__logo"
          />
        </v-col>
      </v-row>
      <v-img
        src="@/assets/home/home-intro-b/xs/xs-2x.png"
        class="home-intro-b__img d-sm-none"
      />
      <v-img
        src="@/assets/home/home-intro-b/sm/sm-2x.png"
        class="home-intro-b__img d-none d-sm-block d-md-none"
      />
      <v-img
        src="@/assets/home/home-intro-b/md/1x-md.png"
        class="home-intro-b__img d-none d-md-block"
      />
    </v-container>
  </section>
</template>

<script>
import HomeDate from "@/components/Home/home-date";

export default {
  components: {
    HomeDate,
  },
  computed: {
    isWindowSM() {
      return this.$store.getters.isWindowSM;
    },
  },
};
</script>


<style lang="scss">
.home-intro-b {
  // background: $golden;
  &__logo {
    width: 132px;
    height: 31px;
    position: relative;
    left: -5px;
    display: inline-block;

    @include up($sm) {
      width: 219px;
      height: 50px;
      left: -1px;
    }

    @include up($sm) {
      margin-bottom: 12px;
    }

    /*@include up($md) {
				margin-bottom: 7px;
			}*/
  }

  &__img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    @include up($sm) {
      min-width: 125%;
      // width: 100%;
      left: 48%;
      top: 0;
    }
    @include up($md) {
      min-width: unset;
      left: 50%;
    }
  }

  &__item {
    margin-bottom: 69%;
    @include up($sm) {
      margin-bottom: 55%;
    }
    @include up($md) {
      margin-bottom: 32%;
    }
  }
}
</style>