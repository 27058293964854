<template>
  <home-speakers-card bg="3" class="--7">
    <h3 class="mb-5 mb-sm-4">
      Уроки финграмотности<br />
      от Тинькофф
    </h3>

    <div class="mt-2 mt-sm-4 mt-md-4 d-flex align-start">
      <v-img
        src="@/assets/home/home-speakers/parents-2x.png"
        class="home-speakers__img"
      />
      <div class="size-sm-md pl-2 pl-sm-2">
        Родителям: лекция эксперта,<br />
        как копить на обучение<br class="d-none d-sm-inline" />
        ребенка
      </div>
    </div>

    <div class="mt-2 mt-sm-4 mb-md-1 d-flex align-start">
      <v-img
        src="@/assets/home/home-speakers/children-2x.png"
        class="home-speakers__img"
      />
      <div class="size-sm-md pl-2 pl-sm-2">
        Детям: бесплатное<br class="d-none d-sm-inline" />
        приложение<br class="d-sm-none" />
        с обучающим<br class="d-none d-sm-inline" />
        курсом о деньгах
      </div>
    </div>
    <div class="home-speakers-card__tinkoff d-flex justify-space-between mb-sm-4">
      <div
        class="home-speakers-card__tinkoff-text size-sm-md dark-grey2 mt-5 mt-sm-7 mr-2"
      >
        Тинькофф Джуниор —<br class="d-none d-sm-inline" />
        сервисы,<br class="d-sm-none" />
        чтобы с детства разбираться<br class="d-sm-none" />
        в деньгах
      </div>
      <v-img
        src="@/assets/home/home-speakers/logo-tinkoff.svg"
        class="home-speakers-card__tinkoff-logo d-sm-none"
      />
      <v-img
        src="@/assets/home/home-speakers/logo-tinkoff-sm.svg"
        class="home-speakers-card__tinkoff-logo d-none d-sm-block"
      />
    </div>
  </home-speakers-card>
</template>

<script>
import HomeSpeakersCard from "@/components/Home/home-speakers-card";

export default {
  components: {
    HomeSpeakersCard,
  },
  computed: {
    isWindowSM() {
      return this.$store.getters.isWindowSM;
    },
    isWindowMD() {
      return this.$store.getters.isWindowMD;
    },
  },
  data: () => {
    return {
      // speakers: [
      // 	/*{
      // 		name: "Анастасия Тарасова",
      // 		image: require("@/assets/speakers/original/speaker-at.png"),
      // 	},*/
      // 	{
      // 		name: "Андрей Абрамов",
      // 		image: require("@/assets/speakers/original/speaker-aa.png"),
      // 	},
      // 	{
      // 		name: "Андрей Иванов",
      // 		image: require("@/assets/speakers/original/speaker-ai.png"),
      // 	},
      // ],
    };
  },
};
</script>

<style lang='scss'>
.home-speakers-card.--7 {
  h3 {
    letter-spacing: -0.5px;
  }
  .size-sm-md {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.35px;

    @include up($sm) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .home-speakers-card {
    &__tinkoff {
      position: relative;
      &-text {
        max-width: 230px;
      }
      &-logo {
        max-width: 77px;
        @include up($sm) {
          max-width: 136px;
          position: absolute;
          right: -24px;
          top: -15px;
        }
        @include up($md) {
          max-width: 136px;
          right: -20px;
        }
      }
    }
  }
  .home-speakers__img {
    max-width: 28px;
    max-height: 28px;
    @include up($sm) {
      max-width: 40px;
      max-height: 40px;
    }
  }
}
</style>