<template>
	<home-speakers-card :items="speakers" bg="3" class="--5">
		<h3>Как перестать ругаться<br/>с ребёнком</h3>
	</home-speakers-card>
</template>

<script>
	import HomeSpeakersCard from "@/components/Home/home-speakers-card";

	export default {
		components: {
			HomeSpeakersCard,
		},
		data: () => {
			return {
				speakers: [
					{
						name: "Лариса Суркова",
						image: require("@/assets/speakers/original/speaker-ls.png")
					},
					/*{
						name: "Ксения Бородина",
						image: require("@/assets/speakers/original/speaker-np.png")
					},*/
					{
						name: "Надя Папудогло",
						image: require("@/assets/speakers/original/speaker-np.png")
					}
				],
			}
		}
	};
</script>

<style lang='scss'>
	.home-speakers-card.--5 {
		.home-speakers-card {
			&__names {
				justify-content: center !important;
				column-gap: 50px;
				transform: translateX(3%);

				@include up($sm) {
					column-gap: 40px;
					transform: translateX(3%);
				}
			}

			&__images {
				height: 135px;
				max-width: 320px;

				& > div {
					&:nth-child(1) {
						transform: translateX(36%);
						z-index: 0;
					}

					&:nth-child(2) {
						transform: translateX(-8%);
					}
				}

				@include up($sm) {
					//height: 140px;
					//max-width: 320px;
				}
			}
		}
	}
</style>